import { Injectable } from '@angular/core';

@Injectable()
export class LoginService {

    public loginLogo = 'logo';

    public defaultInterceptSettings: {url: string, token: string, companyName?: string, module?: string };

    constructor(
    ) {
    }


    setDefaultIterceptorSettings(interceptor: {url: string, token: string, companyName?: string, module?: string }) {
        this.defaultInterceptSettings = interceptor;
    }

}
