import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ErrorService} from "@core/services/error/error.service";

@Component({
    selector: 'mrc-page-404',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
    public errorCode: string;
    public errorMessage: string;

    constructor(
        public activatedRoute: ActivatedRoute,
        private errorService: ErrorService,
    ) { }

    ngOnInit() {
        this.errorCode = this.activatedRoute.snapshot.paramMap.get('errorCode') ?? this.errorService.getErrorCode();

        this.errorMessage = this.errorService.getErrorMessage();

        if(this.errorCode === '404' && '' === this.errorMessage) {
            this.errorMessage = 'Nicht gefunden';
        }

        this.errorService.clearError();
    }
}
