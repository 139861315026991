export class ApplicationError {
    constructor(public message: string) {
    }
}

export class ServerError extends ApplicationError {
    constructor() {
        super('Etwas Schlimmes ist passiert; Bitte versuchen Sie es später erneut.');
    }
}

export class ForbiddenError extends ApplicationError {
    constructor() {
        super('Sie dürfen nicht auf diese Funktion zugreifen');
    }
}
