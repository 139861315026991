import { Injectable } from '@angular/core';
import { ApiInterceptor } from './api.interceptor';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SystemApiInterceptor extends ApiInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return super.intercept(req, next);
    }
}
