import { Observable, ReplaySubject, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { NavigationExtras, Router } from '@angular/router';
import { catchError, mergeMap, tap, map } from 'rxjs/operators';
import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { AuthService } from 'ngx-auth';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthSettingsService } from './auth-settings.service';
import 'url-search-params-polyfill';
import { ProfileService } from '../profile/profile.service';
import { SentryScopeService } from './sentry-scope.service';
import { ToastrService } from 'ngx-toastr';
import { ServerError } from '../../exceptions/error';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';

interface AccessData {
    accessToken: string;
    accessTokenExp: string;
    tokenKey: string;
}

interface Response {
    code: number;
    message: string;
    payload: any;
}

@Injectable()
export class AuthenticationService implements AuthService {
    public isAuthSubject = new ReplaySubject<boolean>();
    public loggedIn = new EventEmitter<any>();
    public tokenKey;
    public user;
    private refreshObservable: Observable<any>;

    constructor(
        private cookie: CookieService,
        private http: HttpClient,
        private tokenStorage: TokenStorageService,
        private router: Router,
        private authSettings: AuthSettingsService,
        private profileService: ProfileService,
        private sentryScope: SentryScopeService,
        private toaster: ToastrService,
        @Optional() @Inject('MODULE_TOKEN') private moduleToken?: any,
        @Optional() @Inject('MODULE_URL') private url?: string
    ) {}

    private static checkErrorIsTokenExpired(response: HttpErrorResponse) {
        return (
            response.status === 401 &&
            response.error &&
            response.error.exceptionCode === 'tokenExpired'
        );
    }

    public login(formData): Observable<any> {
        return this.http.post(`login`, formData).pipe(
            tap((res: Response) => {
                const accessToken = res.payload.token.access_token;
                const accessTokenExp = res.payload.token.expires_in;
                if (
                    res.payload.user &&
                    res.payload.user.roles &&
                    res.payload.permissions
                ) {
                    const { roles, permissions } = res.payload.user;
                    this.authSettings.updateRoles(roles);
                    this.authSettings.updatePermissions(permissions);
                }
                this.user = res.payload.user;
                this.sentryScope.setUser(this.user);
                this.saveAccessData({
                    accessToken,
                    accessTokenExp,
                    tokenKey: this.moduleToken,
                });
                this.loggedIn.emit(this.user);
            })
        );
    }

    public isAuthorized(): Observable<boolean> {
        const isAuthorized: boolean = this.cookie.check(this.moduleToken);
        this.isAuthSubject.next(isAuthorized);
        return of(isAuthorized);
    }

    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken(this.moduleToken);
    }

    public refreshToken(): Observable<any> {
        if (this.refreshObservable) {
            return this.refreshObservable;
        }
        this.refreshObservable = this.http.post('refresh', {}).pipe(
            catchError((err) => {
                if (err.error.message === 'token_expired') {
                    this.toaster.warning(
                        'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.'
                    );
                } else {
                    this.toaster.error(new ServerError().message);
                    console.error(err);
                }
                this.refreshObservable = null;

                return of(this.logout()).pipe(mergeMap(() => throwError(err)));
            }),
            tap((res: Response) => {
                this.refreshObservable = null;
                const accessToken = res.payload.access_token;
                const accessTokenExp = res.payload.expires_in;
                if (
                    res.payload.user &&
                    res.payload.user.roles &&
                    res.payload.permissions
                ) {
                    const { roles, permissions } = res.payload.user;
                    this.authSettings.updateRoles(roles);
                    this.authSettings.updatePermissions(permissions);
                }
                const role = '';
                this.saveAccessData({
                    accessToken,
                    accessTokenExp,
                    tokenKey: this.moduleToken,
                });
            })
        );
        return this.refreshObservable;
    }

    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        if (this.tokenStorage.getAccessTokenSync(this.moduleToken)) {
            return (
                this.hasTokenExpired() ||
                AuthenticationService.checkErrorIsTokenExpired(response)
            );
        }
        if (!this.isLoginOrRegisterUrl()) {
            this.logout(false);
        }
        return false;
    }

    public verifyTokenRequest(url: string): boolean {
        return url.endsWith('refresh');
    }

    public async logout(removeTokens = true) {
        if (removeTokens) {
            this.tokenStorage.clearAll();
        }
        this.profileService.clearCache();

        let redirectUrl = `${this.authSettings.path}/login`;

        /**
         *  @TODOs @ESONOs
         *  Not sure, if we need this anymore
         */
        const developmentHostnames = [
            'login.mercator.test',
            'login.meinfirmenroller.services',
        ];

        if (developmentHostnames.includes(window.location.hostname) === true) {
            redirectUrl = `/login`;
        }

        try {
            this.http.post(`${this.url}/logout`, {});
            this.profileService.getProfile();

            const navigationExtras: NavigationExtras = {
                queryParams: {
                    token_type: null,
                    access_token: null,
                    expire: null,
                },
                queryParamsHandling: 'merge',
            };

            this.router.navigateByUrl(redirectUrl, navigationExtras);
        } catch (err) {
            console.error(err);
        }
    }

    public hasTokenExpired(): boolean {
        return (
            !this.tokenStorage.checkAccessTokenExpiration(this.moduleToken) &&
            this.tokenStorage.checkAccessToken(this.moduleToken)
        );
    }

    public isLoginOrRegisterUrl(): boolean {
        return (
            this.router.url &&
            (this.router.url.includes('login') ||
                this.router.url.includes('register') ||
                this.router.url.includes('forgot-password') ||
                this.router.url.includes('reset') ||
                this.router.url == '/')
        );
    }

    public saveAccessData({
        accessToken,
        accessTokenExp,
        tokenKey,
    }: AccessData) {
        this.tokenStorage.setAccessToken(accessToken, tokenKey);
        this.tokenStorage.setAccessTokenExpiration(accessTokenExp, tokenKey);
    }

    checkTokenValidity(token: string): Observable<boolean> {
        if ('' === token) {
            return of(false);
        }

        let redirectUrl = `api/calculator_partner/validate_token`;

        return this.http.get<any>(redirectUrl, {
            headers: { Authorization: `Bearer ${token}` }
        }).pipe(
            map(response => {
                return response.valid;
            }),
            catchError(() => of(false))
        );
    }
}
