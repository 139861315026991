import { Component, ViewChild, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { HttpConfigService } from '../../core/services/http/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../core/services/login/login.service';
import { CompaniesService } from '../../companies/companies.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Company } from '../../shared/models/company';

@Component({
    selector: 'mrc-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    @ViewChild('forgotPasswordForm') forgotPasswordForm: HTMLFormElement;
    public submitted = false;
    public title = 'Passwort zurücksetzen';
    public childCompanies: Company[];

    constructor(
                @Inject('MODULE') public module: string,
                private toasterService: ToastrService,
                private httpService: HttpConfigService,
                public route: ActivatedRoute,
                private companiesService: CompaniesService,
                public loginService: LoginService) {
        if (this.route.parent.parent.parent) {
            this.route.parent.parent.parent.params.subscribe((params) => {
                if (this.module === 'company' || this.module === 'employee') {
                    const companySlug = params.companySlug;
                    this.companiesService
                        .getBySlug(companySlug)
                        .pipe(catchError((error) => throwError(error)))
                        .subscribe((company) => {
                            this.companiesService
                                .childs(company.id)
                                .subscribe(
                                    (companies) =>
                                        (this.childCompanies = companies)
                                );
                        });
                }
            });
        }
    }
    forgotPassword(form: NgForm) {
        const data = form.form.value;

        this.httpService.postRequest('password/email', data)
            .subscribe(
                res => {
                    this.toasterService.success('Vielen Dank! Falls ein Konto mit der angegebenen E-Mail-Adresse existiert, haben wir Anweisungen zum Zurücksetzen des Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang.');

                    this.submitted = true;
                },
                res => {

                    if (res.status === 422 || res.status === 500) {
                        this.httpService.setFormErrors(this.forgotPasswordForm, res.error.payload);
                    }
                }
            );
    }
}
