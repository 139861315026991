import { HasProductCategory } from './has-product-category';
import * as parseDate from 'date-fns/parse';
import * as isPast from 'date-fns/is_past';
import * as isFuture from 'date-fns/is_future';
import * as startOfTomorrow from 'date-fns/start_of_tomorrow';
import * as startOfToday from 'date-fns/start_of_today';
import { ProductCategory } from './product-category';
import { Mixin } from '../../common/mixin';
import { Model } from './model';

export interface LeasingConditionMixin extends Model, HasProductCategory {}
export class LeasingCondition extends Mixin<LeasingConditionMixin>(
    Model,
    HasProductCategory
) {
    public factor: number;
    public id: number;
    public key: number | string;
    public name: string;
    public period = 6;
    public productCategory: ProductCategory;
    public residualValue = 0;
    public activeAt = startOfToday();
    public inactiveAt = null;
    public default: boolean;

    getLeasingFactor(): number {
        return this.factor / 100;
    }

    reactivate(): void {
        this.inactiveAt = null;
    }

    cancelDeactivation(): void {
        this.inactiveAt = null;
    }

    deactivateTomorrow(): void {
        this.inactiveAt = startOfTomorrow();
    }

    deactivateToday(): void {
        this.activeAt = startOfToday();
        this.inactiveAt = startOfToday();
    }

    activate(): void {
        this.activeAt = startOfToday();
        this.inactiveAt = null;
    }

    activateTomorrow(): void {
        this.activeAt = startOfTomorrow();
        this.inactiveAt = null;
    }

    isInactive(): boolean {
        return (
            isPast(this.activeAt) && this.inactiveAt && isPast(this.inactiveAt)
        );
    }

    isActive(): boolean {
        return (
            isPast(this.activeAt) &&
            (!this.inactiveAt || isFuture(this.inactiveAt))
        );
    }

    isDeactivatedTomorrow(): boolean {
        return (
            isPast(this.activeAt) &&
            this.inactiveAt &&
            isFuture(this.inactiveAt)
        );
    }

    isFuture(): boolean {
        return isFuture(this.activeAt) && this.inactiveAt === null;
    }

    setDefault(value: boolean) {
        this.default = value;
    }
}
