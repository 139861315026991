import { Component, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../core/services/auth/auth.service';
import { LoginService } from '../../core/services/login/login.service';
import { Subscription, throwError } from 'rxjs';
import { AuthSettingsService } from '../../core/services/auth/auth-settings.service';
import { ToastrService } from 'ngx-toastr';
import { ForbiddenError } from '../../core/exceptions/error';
import { catchError } from 'rxjs/operators';
import { Company } from '../../shared/models/company';
import { CompaniesService } from '../../companies/companies.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'mrc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
    @ViewChild('loginForm') loginForm: HTMLFormElement;
    public errorMessage: string;
    public submitProcess = false;
    public email;
    public password;
    public companyId;
    public loginSubscription: Subscription;
    public childCompanies: Company[];

    constructor(
        @Inject('MODULE') public module: string,
        private authService: AuthenticationService,
        public loginService: LoginService,
        public route: ActivatedRoute,
        private companiesService: CompaniesService,
        private toaster: ToastrService,
        private authSettings: AuthSettingsService,
        public dialog: MatDialog,
        ) {
        this.route.parent.params.subscribe(params => {
            if (this.module === 'company') {
                const companySlug = params.companySlug;
                this.companiesService
                    .getBySlug(companySlug)
                    .pipe(catchError(error => throwError(error)))
                    .subscribe(company => {
                        this.companiesService
                            .childs(company.id)
                            .subscribe(
                                companies => (this.childCompanies = companies)
                            );
                    });
            }
        });
    }

    login(form: NgForm) {
        this.submitProcess = true;
        const data = form.form.value;
        this.loginSubscription = this.authService.login(data)
            .pipe(catchError(error => {
                this.submitProcess = false;
                if (error instanceof ForbiddenError) {
                    this.toaster.error(error.message);
                } else {
                    this.toaster.error(error.error.message);
                }
                return throwError(error);
            }))
            .subscribe(async res => {
                const { roles, permissions } = res.payload.user;
                this.authSettings.updateRoles(roles);
                this.authSettings.updatePermissions(permissions);
                this.errorMessage = '';
                this.submitProcess = false;
            });
    }

    ngOnDestroy() {
        this.errorMessage = '';
    }

}
