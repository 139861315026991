import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { InterceptSettingsService } from './intercept-settings.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/auth.service';
import { LoadingService } from '../loading/loading.service';
import { FormUtilities } from '../../forms/form-utilities';


@Injectable()
export class HttpConfigService implements HttpInterceptor {

    constructor(public http: HttpClient,
                public cookie: CookieService,
                public toaster: ToastrService,
                @Inject('MODULE_TOKEN') public token,
                @Inject('MODULE_URL') public url,
                private formUtilities: FormUtilities,
                private router: Router,
                private authService: AuthenticationService,
                private interceptService: InterceptSettingsService,
                private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.warn('HttpConfigService is deprecated. You should use HttpClient and ApiInterceptor.');
        const settings = this.interceptService.settings;
        let headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.cookie.get(this.token)}`,
        });
        const url = `${this.url}/${req.url}`;
        const apiReq = req.clone({
            url,
            headers,
        });
        this.loadingService.queueRequest(req);
        return next.handle(apiReq)
            .pipe(
                finalize(() => this.loadingService.removeFromQueue(req))
            );
    }

    public setFormErrors(form, errors, prefix = ''): void {
        console.warn('HttpConfigService.setFormErrors is deprecated. Please use the FormUtilities service.');
        this.formUtilities.setFormErrors(form, errors, prefix = '');
    }

    getRequest(url, options = {}): Observable<any> {
        console.warn('HttpConfigService is deprecated. You should use HttpClient and ApiInterceptor.');
        return this.http.get<any>(url, options);
    }

    postRequest(url, data?, options = {}): Observable<any> {
        console.warn('HttpConfigService is deprecated. You should use HttpClient and ApiInterceptor.');
        return this.http.post(url, data, options);
    }

    putRequest(url, data?, options = {}): Observable<any> {
        console.warn('HttpConfigService is deprecated. You should use HttpClient and ApiInterceptor.');
        return this.http.put(url, data, options);
    }

    deleteRequest(url, options = {}): Observable<any> {
        console.warn('HttpConfigService is deprecated. You should use HttpClient and ApiInterceptor.');
        return this.http.delete(url, options);
    }

    getFileRequest(url) {
        return this.http.get(url, {
            observe: 'response',
            responseType: 'blob',
        }).pipe(map(
            (res: HttpResponse<Blob>) => {
                return {
                    headers: res.headers,
                    body: res.body,
                };
            }));
    }

    public export(route: string, filename: string = 'export') {
        const url = `${route}/export`;

        this.getFileRequest(url).subscribe(res => {
            filename = filename + '.' + res.headers.get('content-disposition').match(/filename=(.+)/)[1].slice(0, -1).split('.')[1];
            saveAs(res.body, filename);
        });
    }
}
