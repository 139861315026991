import { Factory } from '../../../shared/factory';
import { Injectable } from '@angular/core';
import { Status } from '../../../shared/models/status';

@Injectable({
    providedIn: 'root',
})
export class StatusFactory extends Factory<Status> {
    constructor() {
        super(Status);
    }
}
