import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { ProfileService } from '../../core/services/profile/profile.service';

export class MenuElement {
    menu: any[];
}

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public menuSubject = new BehaviorSubject(new MenuElement());

    constructor(private profileService: ProfileService) {}

    public updateMenu(value: Array<any>, permissions: Array<any>) {
        this.menuSubject.next(
            this.getPermissionsMenu(value, permissions ? permissions : [])
        );
    }

    getPermissionsMenu(
        menu: Array<any>,
        permissions: Array<string>
    ): MenuElement {
        const menuEle = new MenuElement();
        menuEle.menu = menu.map((submenu) => {
            submenu.links = submenu.links.filter((link) => {
                if (link.permissions && link.permissions.length > 0) {
                    return link.permissions.reduce((hasPermission, role) => {
                        return permissions.includes(role);
                    }, true);
                }
                return true;
            });
            return submenu;
        });
        return menuEle;
    }
}
