import { Injectable } from '@angular/core';
import { Factory } from '../shared/factory';
import { Company, CompanyStatus } from '../shared/models/company';
import { Portal, PortalStatus } from '../shared/models/portal';
import { Status } from '../shared/models/status';

@Injectable({
    providedIn: 'root',
})
export class CompaniesFactory extends Factory<Company> {
    constructor() {
        super(Company);
    }

    create(): Company {
        const company = super.create();
        const status = new Factory(Status).create();
        status.id = CompanyStatus.Active;
        company.status = status;
        return company;
    }
}
