import { Company } from './company';
import { FinancialInformation } from './financial-information';
import { Model } from './model';

export class Profile extends Model {
    active_contracts: number;
    max_user_contracts: number;
    max_user_amount: number;
    first_name: string;
    last_name: string;
    fullName: string;
    company: Company;
    email: string;
    salutation: string;
    street: string;
    phone: string;
    city_id: number | string;
    country: string;
    employee_number: string;
    postal_code: string | number;
    postal_code_center: string;
    password: string;
    password_confirmation: string;
    roles: Array<string> = [];
    permissions: Array<{ id: number, name: string}> = [];
    portalId: number;
    policy_checked: boolean;
    apartment_workplace_distance: number;
    is_password_expired: boolean;
    financial_information: FinancialInformation;

    user_ip: string;
    can_export_users?: boolean;

    constructor(profile?: Profile) {
        super();
        if (profile) {
            this.id = profile.id;
            this.portalId = profile.portalId;
            this.company = profile.company;
            this.first_name = profile.first_name;
            this.fullName = profile.fullName;
            this.last_name = profile.last_name;
            this.email = profile.email;
            this.salutation = profile.salutation;
            this.phone = profile.phone;
            this.street = profile.street;
            this.city_id = profile.city_id;
            this.country = profile.country;
            this.postal_code = profile.postal_code;
            this.postal_code_center = profile.postal_code_center;
            this.employee_number = profile.employee_number;
            this.roles = profile.roles;
            this.permissions = profile.permissions;
            this.policy_checked = profile.policy_checked;
            this.apartment_workplace_distance = profile.apartment_workplace_distance;
            this.active_contracts = profile.active_contracts;
            this.max_user_contracts = profile.max_user_contracts;
            this.max_user_amount = profile.max_user_amount;
            this.is_password_expired = profile.is_password_expired;
            this.financial_information = new FinancialInformation(profile.financial_information);
            this.user_ip = profile.user_ip;
            this.can_export_users = profile.can_export_users;
        }
    }

    public isCompanyAdmin(): boolean {
        return this.roles && this.roles.includes('Company Admin');
    }

    public isEmployee(): boolean {
        return this.roles && this.roles.includes('Employee');
    }

    public isSupplier(): boolean {
        return this.roles && this.roles.includes('Supplier Admin');
    }

    public isPortalAdmin(): boolean {
        return this.roles && this.roles.includes('Portal Admin');
    }

    public isSystemAdmin(): boolean {
        return this.roles && this.roles.includes('Systemadministrator');
    }

    public isEmployeeAdministrator(): boolean {
        return this.isCompanyAdmin() && this.isEmployee();
    }

    public hasPermission(permissionName): boolean {
        let found = false;
        if (!this.permissions || this.permissions.length === 0){
            return this.isSystemAdmin();
        }
        this.permissions.forEach(permission => {
            if (permission.name === permissionName) {
                found = true;
                return;
            }
        });
        return found;
    }
}
