import { ModuleWithProviders } from '@angular/core';
import {
    Routes,
    RouterModule,
    NoPreloading,
    PreloadAllModules,
} from '@angular/router';
import { LoginComponent } from '../public/login/login.component';
import { LoginDeutscheDienstradComponent } from '../public/login-dd/login-dd.component';
import { PublicGuard, ProtectedGuard } from 'ngx-auth';
import { ForgotPasswordComponent } from '../public/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../public/reset-password/reset-password.component';
import { ErrorPageComponent } from '../public/error-page/error-page.component';

const routes: Routes = [
    {
        path: 'admin',
        canActivate: [ProtectedGuard],
        loadChildren: () =>
            import('./admin/system-admin.module').then(
                (m) => m.SystemAdminModule
            ),
        data: { preload: false },
    },
    {
        path: 'login',
        canActivate: [PublicGuard],
        component: LoginComponent,
    },
    {
        path: 'unilogin',
        component: LoginDeutscheDienstradComponent,
    },
    {
        path: 'password',
        canActivate: [PublicGuard],
        children: [
            {
                path: 'forgot-password',
                canActivate: [PublicGuard],
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset',
                canActivate: [PublicGuard],
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        path: '',
        redirectTo: '/admin/dashboard',
        pathMatch: 'prefix',
    },
    {
        path: '**',
        redirectTo: '/error/404'
    },
    {
        path: 'error/:errorCode',
        loadChildren: () => ErrorPageComponent,
    },
];

export const SystemAdminRoutes: ModuleWithProviders<any> = RouterModule.forRoot(
    routes,
    {
        preloadingStrategy: PreloadAllModules,
    }
);
