<div>
    <div class="form-group" [ngClass]="{'form-group-inline': layout == 'inline'}">
        <div class="d-flex justify-content-between align-items-baseline">
            <label *ngIf="label" [class]="'form-label-sm' + (isOutCard && ' out-card')"
                   [attr.for]="id" [innerHtml]="label"></label>

            <button class="btn btn-icon d-none d-lg-block"
                    [tooltip]="tooltipText"
                    *ngIf="tooltipText"
                    placement="top"
                    container="body"
                    type="button"
                    tooltipClass="tooltip-info">
                <i class="fal fa-info-circle info"></i>
            </button>

            <button class="btn btn-icon d-lg-none d-block"
                    [tooltip]="tooltipText"
                    *ngIf="tooltipText"
                    placement="left"
                    container="body"
                    type="button"
                    trigger="click"
                    tooltipClass="tooltip-info">
                <i class="fal fa-info-circle info"></i>
            </button>
        </div>

        <div class="form-control-holder">
            <ng-content></ng-content>

            <mrc-validation-errors [dirty]="control?.dirty" [invalid]="control?.invalid" [errors]="control?.errors" [direction]="direction"></mrc-validation-errors>
        </div>
    </div>
</div>
