export enum RelationType {
    HasOne, HasMany, BelongsTo, BelongsToMany
}

export class Relation {
    constructor(public classType,
                public field: string,
                public type: RelationType) {

    }
}
