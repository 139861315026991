import { InsuranceRate } from './insurance-rate';
import { ServiceRate } from './service-rate';
import { LeasingCondition } from './leasing-condition';
import { Model } from './model';
import { ProductCategory } from './product-category';
import { PortalLeasingCondition } from './portal-leasing-condition';
import { Mixin } from '../../common/mixin';
import { HasMany } from '../../core/relations/has-many';

@HasMany(LeasingCondition, 'leasingConditions')
@HasMany(InsuranceRate, 'insuranceRates')
@HasMany(ServiceRate, 'serviceRates')
export class HasLeasingSettings extends Model {

    public leasingConditions: Array<LeasingCondition> = [];
    public insuranceRates: Array<InsuranceRate> = [];
    public serviceRates: Array<ServiceRate> = [];

    getDefaultLeasingCondition(productCategory: ProductCategory): PortalLeasingCondition {
        return this.leasingConditions.find(item => item.default && item.productCategory.id === productCategory.id);
    }

    getDefaultInsuranceRate(productCategory: ProductCategory): InsuranceRate {
        return this.insuranceRates.find(item => item.default && item.productCategory.id === productCategory.id);
    }

    getDefaultServiceRate(productCategory: ProductCategory): ServiceRate {
        return this.serviceRates.find(item => item.default && item.productCategory.id === productCategory.id);
    }

    addLeasingCondition(leasingCondition: LeasingCondition): void {
        if (this.leasingConditions.filter(t => t.productCategory === leasingCondition.productCategory)) {
            leasingCondition.deactivateToday();
        }
        this.leasingConditions = [...this.leasingConditions, leasingCondition];
    }

    addInsuranceRate(rate: InsuranceRate): void {
        this.insuranceRates = [...this.insuranceRates, rate];
    }

    addServiceRate(rate: ServiceRate): void {
        this.serviceRates = [...this.serviceRates, rate];
    }

    editInsuranceRate(rate: InsuranceRate): void {
        const foundRate = this.insuranceRates
            .find(item => item.id === rate.id);
        Object.assign(foundRate, rate);
        this.insuranceRates = [...this.insuranceRates];
    }

    editServiceRate(rate: ServiceRate): void {
        const foundRate = this.serviceRates
            .find(item => item.id === rate.id);
        Object.assign(foundRate, rate);
        this.serviceRates = [...this.serviceRates];
    }

    editLeasingCondition(leasingCondition: LeasingCondition) {
        const foundLeasingCondition = this.leasingConditions
            .find(item => item.id === leasingCondition.id);
        Object.assign(foundLeasingCondition, leasingCondition);
        this.leasingConditions = [...this.leasingConditions];
    }

    deleteLeasingCondition(leasingCondition: LeasingCondition) {
        this.leasingConditions.splice(this.leasingConditions.indexOf(leasingCondition), 1);
        this.leasingConditions = [...this.leasingConditions];
    }

    deleteInsuranceRate(rate: InsuranceRate) {
        this.insuranceRates.splice(this.insuranceRates.indexOf(rate), 1);
        this.insuranceRates = [...this.insuranceRates];
    }

    deleteServiceRate(rate: InsuranceRate) {
        this.serviceRates.splice(this.serviceRates.indexOf(rate), 1);
        this.serviceRates = [...this.serviceRates];
    }

    getActiveLeasingCondition(productCategory: ProductCategory): LeasingCondition {
        return this.leasingConditions.find(item => item.isActive() && item.productCategory.id === productCategory.id);
    }

    activateLeasingCondition(leasingCondition: LeasingCondition) {
        this.deactivateActiveCondition(leasingCondition.productCategory);
        this.deactivateFutureCondition(leasingCondition.productCategory);
        this.findAndActivateCondition(leasingCondition);
        this.leasingConditions = [...this.leasingConditions];
    }

    deactivateLeasingCondition(leasingCondition: LeasingCondition) {
        this.getActiveLeasingCondition(leasingCondition.productCategory).activate();
        this.deactivateFutureCondition(leasingCondition.productCategory);
        this.leasingConditions = [...this.leasingConditions];
    }

    private deactivateActiveCondition(productCategory: ProductCategory) {
        const activeLeasingCondition = this.getActiveLeasingCondition(productCategory);
        if (activeLeasingCondition) {
            activeLeasingCondition.deactivateTomorrow();
        }
    }

    private deactivateFutureCondition(productCategory: ProductCategory) {
        const futureLeasingCondition = this.leasingConditions
            .find(item => item.isFuture() && productCategory.id === item.productCategory.id);
        if (futureLeasingCondition) {
            futureLeasingCondition.deactivateToday();
        }
    }

    private findAndActivateCondition(leasingCondition: LeasingCondition) {
        const futureLeasingCondition = this.leasingConditions
            .find(item => item.id === leasingCondition.id);
        if (futureLeasingCondition) {
            futureLeasingCondition.activateTomorrow();
        }
    }
}
