import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { HttpConfigService } from '../../core/services/http/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../core/services/login/login.service';
import {RegistrationComponent} from '../registration/registration.component';
import { ProfileService } from '../../core/services/profile/profile.service';
import { Profile } from '../../shared/models/profile';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormUtilities } from '../../core/forms/form-utilities';
import { throwError } from 'rxjs';

class Reset {
    public password: string;
    public password_confirmation: string;
    public token: string;
    public email: string;

    constructor(reset?) {
        if (reset) {
            this.password = reset.password;
            this.password_confirmation = reset.password_confirmation;
            this.token = reset.token;
            this.email = reset.email;
        }
    }
}

@Component({
    selector: 'mrc-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild('resetPasswordForm')
    resetPasswordForm: HTMLFormElement;
    private queryParams: object;
    public reset = new Reset();
    public profile = new Profile();
    public errorMessage: string;
    public isValidPassword = true;
    public showPasswordTips = false;
    public submitProcess = false;

    constructor(
        private http: HttpClient,
        private activatedRoute: ActivatedRoute,
        public loginService: LoginService,
        public profileService: ProfileService,
        private toasterService: ToastrService,
        private formUtilities: FormUtilities,
        private router: Router
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            const token = params.get('token');
            const email = params.get('email');

            this.reset = new Reset({ token, email });
        });
    }

    resetPassword(form: NgForm) {
        Object.keys(form.controls).forEach((key, val) => {
            form.controls[key].markAsDirty();
        });
        if (form.invalid) {
            return;
        }
        this.submitProcess = true;
        const data = form.form.value;
        this.http
            .post(`password/reset`, data)
            .pipe(
                catchError((error) => {
                    if (error.status === 422) {
                        switch (error.error.exceptionCode) {
                            case 'validationFailed':
                                if (error.error.payload.password
                                    && error.error.payload.password.length > 0
                                    && error.error.payload.password[0] === 'validation.new_password') {
                                    this.formUtilities.setFormErrors(this.resetPasswordForm, {
                                        // tslint:disable-next-line: max-line-length
                                        password: 'Das von Ihnen eingegebene Passwort wurde schon einmal verwendet, versuchen Sie ein neues Passwort.'
                                    });
                                } else {
                                    this.formUtilities.setFormErrors(this.resetPasswordForm, error.error.payload);
                                }
                                break;
                            default:
                                this.formUtilities.setFormErrors(this.resetPasswordForm, error.error.payload);
                        }
                    }
                    this.submitProcess = false;
                    return throwError(error);
                })
            )
            .subscribe((res: any) => {
                this.submitProcess = false;
                this.toasterService.success(
                    'Vielen Dank! Falls ein Konto mit der angegebenen E-Mail-Adresse existiert, haben wir einen Link zum Zurücksetzen des Passworts gesendet. Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen im E-Mail, um Ihr Passwort zurückzusetzen.'
                );
                this.router.navigateByUrl(`${res.payload.redirect_to}/login`);
            });
    }

    onPasswordChange() {
        const password = this.reset.password;
        if (password) {
            if (!RegistrationComponent.minimum8rule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.numsAndLettersRule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.lowerAndUpperRule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.specialCharsRule(password)) {
                this.isValidPassword = false;
                return;
            }
            this.isValidPassword = true;
        }
    }

    onPasswordFocus() {
        this.showPasswordTips = true;
    }

    onPasswordBlur() {
        this.showPasswordTips = false;
    }
}
