import { Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import { User } from '../../../shared/models/user';
import { Profile } from '../../../shared/models/profile';

@Injectable({providedIn: 'root'})
export class SentryScopeService {

    setUser(user: User): void {
        Sentry.configureScope(scope => {
            if (user && user.id) {
                scope.setUser({
                    id: user.id.toString(),
                    email: user.email,
                });
            }
        });
    }

    setUserByProfile(user: Profile): void {
        Sentry.configureScope(scope => {
            if (user && user.id) {
                scope.setUser({
                    id: user.id.toString(),
                    email: user.email,
                });
            }
        });
    }
}
