import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Company } from '../shared/models/company';
import { CompaniesFactory } from './companies.factory';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { User } from '../shared/models/user';
import { UsersFactory } from '../core/services/mercator/users.factory';
import { HasLeasingSettings } from '../shared/models/has-leasing-settings';
import { Factory } from '../shared/factory';
import { parse } from 'date-fns';
import { Status } from '../shared/models/status';

@Injectable()
export class CompaniesService {
    constructor(
        private httpClient: HttpClient,
        private companyFactory: CompaniesFactory,
        private toaster: ToastrService,
        private userFactory: UsersFactory
    ) {}

    all(
        portalId: number,
        parentCompanyId = null,
        status = null,
        showParentCompanies = null
    ): Observable<Company[]> {
        let url = 'companies/all';
        if (portalId) {
            url += `?portal_id=${portalId}`;
        }
        if (parentCompanyId) {
            if (portalId) {
                url += '&';
            } else {
                url += '?';
            }
            url += `parent_company_id=${parentCompanyId}`;
        }
        if (status) {
            if (portalId) {
                url += '&';
            } else if (parentCompanyId) {
                url += '&';
            } else {
                url += '?';
            }
            url += `status=${status}`;
        }
        if (showParentCompanies != null) {
            if (portalId) {
                url += '&';
            } else if (parentCompanyId) {
                url += '&';
            } else if (status) {
                url += '&';
            } else {
                url += '?';
            }
            url += `show_parent_companies=${showParentCompanies}`;
        }
        return this.httpClient
            .get<any>(url)
            .pipe(map(companies => new Factory(Company).fromArray(companies)));
    }

    allForSupplier(): Observable<Company[]> {
        const url = 'companies/allForSupplier';
        return this.httpClient
            .get<any>(url)
            .pipe(map(companies => new Factory(Company).fromArray(companies)));
    }

    childs(parentCompanyId: number): Observable<Company[]> {
        const url = `companies/childs?parent_company_id=${parentCompanyId}`;
        return this.httpClient
            .get<any>(url)
            .pipe(map(companies => new Factory(Company).fromArray(companies)));
    }

    childsAndParents(id: number, email = ''): Observable<Company[]> {
        let url = `companies/childsAndParents/${id}`;
        if (email !== '') {
            url += `?searchEmail=${email}`;
        }
        return this.httpClient
            .get<any>(url)
            .pipe(map(companies => new Factory(Company).fromArray(companies)));
    }

    allCompanies(): Observable<Company[]> {
        const url = 'companies/allCompanies';
        return this.httpClient
            .get<any>(url)
            .pipe(map(companies => new Factory(Company).fromArray(companies)));
    }

    get(id): Observable<Company> {
        return this.httpClient
            .get(`companies/${id}`)
            .pipe(map(responseBody => this.parseResponse(responseBody)));
    }

    getBySlug(slug: string): Observable<Company> {
        return this.httpClient
            .get(`companies/slug/${slug}`)
            .pipe(map(company => new Factory(Company).fromObject(company)));
    }

    checkSlug(slug: string): Observable<boolean> {
        return this.httpClient
            .get<{ exists: boolean }>(`companies/slug-exists?slug=${slug}`)
            .pipe(map(response => response.exists));
    }

    getDefaultLeasingSettings(): Observable<HasLeasingSettings> {
        return this.httpClient
            .get<any>('leasing-settings', {})
            .pipe(
                map(response =>
                    new Factory(HasLeasingSettings).fromObject(response)
                )
            );
    }

    create(company: Company): Observable<Company> {
        return this.httpClient.post<Company>(`companies`, company).pipe(
            map(responseBody => this.parseResponse(responseBody)),
            tap(() =>
                this.toaster.success('Firma wurde erfolgreich hinzugefügt')
            )
        );
    }

    update(company: Company) {
        return this.httpClient.put(`companies/${company.id}`, company).pipe(
            map(responseBody => this.parseResponse(responseBody)),
            tap(() =>
                this.toaster.success('Firma wurde erfolgreich aktualisiert')
            )
        );
    }

    getEmployees(companyId: number): Observable<User[]> {
        return this.httpClient
            .get<any[]>(`companies/${companyId}/employees`)
            .pipe(
                map(responseBody => this.userFactory.fromArray(responseBody))
            );
    }

    export(exportSettings: any): Observable<any> {
        return new Observable(observer => {
            this.httpClient
                .post(
                    'export/company',
                    { exportSettings: exportSettings },
                    {
                        responseType: 'blob',
                        headers: {
                            Accept: 'application/json; charset=utf-8',
                            'Content-Type': 'application/json; charset=utf-8',
                            'X-Skip-Module-Url': ''
                        },
                        observe: 'response'
                    }
                )
                .subscribe(
                    (res: HttpResponse<Blob>) => {
                        const content = res.headers.get('content-disposition');
                        if (content) {
                            const filename = content
                                .match(/filename=(.+)/)[1]
                                .replace(/'/g, '');
                            saveAs(res.body, filename);
                        }
                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        console.error(error);
                        observer.next(false);
                        observer.complete();
                    }
                );
        });
    }

    private parseResponse(response: any): Company {
        const company = <Company>this.companyFactory.fromObject(response);
        company.end_contract = parse(company.end_contract);
        return company;
    }

    getStatuses(): Observable<Status[]> {
        const url = 'companies/statuses';
        return this.httpClient.get<Status[]>(url).pipe(
            map(res => res),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    private handleError(error: any): Observable<never> {
        return throwError(error);
    }
}
