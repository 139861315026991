import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from '../../login.service';
import { Observable, of, throwError } from 'rxjs';
import { LoginVerification } from '../login-verification';
import {catchError, filter, map, tap} from 'rxjs/operators';
import { Factory } from '../../../shared/factory';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ConsentResolver implements Resolve<LoginVerification>
{
    public constructor(
        public loginService: LoginService,
        public router: Router,
    ) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<LoginVerification> | Promise<LoginVerification> | LoginVerification {
            const challenge = route.queryParamMap.get('consent_challenge');
            return this.loginService.verifyConsentChallenge(challenge)
                .pipe(
                    map(response => new Factory(LoginVerification).fromObject(response)),
                    filter(verification => {
                        if (verification.skip && verification.redirectTo) {
                            window.location.replace(verification.redirectTo);
                            return false;
                        }
                        return true;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.router.navigate(['error', error.status], {
                            queryParams: {
                                message: 'Bei der Bestätigung Ihres Logins ist ein Fehler aufgetreten',
                            },
                        });
                        return throwError(error);
                    })
                );
    }
}
