import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InterceptSettingsService {
    public settings: { companyName?: string, module?: string };
    public settingsSubject = new ReplaySubject();

    public error: any;

    constructor(@Inject('MODULE_TOKEN') token, @Inject('MODULE_URL') url) {
        this.settings = {};
        this.settingsSubject.subscribe((value: { companyName?: string, module?: string}) => {
            this.settings = value;
        });
    }

    updateSettings(value) {
        this.settingsSubject.next(value);
    }


}
