import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'mrc-form-item-field',
    templateUrl: './form-item-field.component.html',
    styleUrls: ['./form-item-field.component.scss']
})
export class FormItemFieldComponent {
    @Input() control: UntypedFormControl;
    @Input() key: string;
    @Input() label: string;
    @Input() id: string;
    @Input() layout: string;
    @Input() direction: Array<string> = ['lg-right'];
    @Input() tooltipText: string;
    @Input() isOutCard: boolean;


}
