import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../core/services/login/login.service';
import { NgForm } from '@angular/forms';
import { HttpConfigService } from '../../core/services/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthSettingsService } from '../../core/services/auth/auth-settings.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormUtilities } from '../../core/forms/form-utilities';
import { CompaniesService } from '../../companies/companies.service';
import { Company } from '../../shared/models/company';
import {environment} from "@environments/environment";
import {SettingsService} from "@core/services/settings/settings.service";

class RegistrationInfo {
    public email: string;
    public first_name: string;
    public last_name: string;
    public password: string;
    public password_confirmation: string;
    public employee_number: string;
    public companyId: number;

    constructor () {}
}

@Component({
    selector: 'mrc-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    @ViewChild('registrationForm')
    registrationForm: HTMLFormElement;
    public errorMessage: string;
    public submitProcess = false;
    public registrationInfo: RegistrationInfo = new RegistrationInfo();
    public urlId: string;
    public isValidPassword = true;
    public showPasswordTips = false;
    public company: Company;
    public childCompanies: Company[];
    private primaryColor = '#f9a110';
    public loginLogo = 'assets/images/logos/companyadministrator_logo.svg';

    static minimum8rule(value) {
        return value.length > 7 && value.length < 16;
    }
    static numsAndLettersRule(value) {
        const numRegex = new RegExp(/[0-9]/);
        const letterRegex = new RegExp(/[A-Za-z]/);
        return numRegex.test(value) && letterRegex.test(value);
    }
    static lowerAndUpperRule(value) {
        const lowerRegex = new RegExp(/[a-z_]/);
        const upperRegex = new RegExp(/[A-Z_]/);
        return lowerRegex.test(value) && upperRegex.test(value);
    }
    static specialCharsRule(value) {
        const regex = new RegExp(/[^a-zA-Z0-9]/);
        return regex.test(value);
    }
    static noSpacesRule(value) {
        const regex = new RegExp(/\s/);
        return !regex.test(value);
    }
    noUsernameRule(value) {
        return (
            !value.includes(this.registrationInfo.email) &&
            !value.includes(this.registrationInfo.first_name) &&
            !value.includes(this.registrationInfo.last_name)
        );
    }

    constructor(
        public loginService: LoginService,
        public route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private formUtilities: FormUtilities,
        private toaster: ToastrService,
        private companiesService: CompaniesService,
        public settingsService: SettingsService,
    ) {
        this.route.parent.params.subscribe(params => {
            const companySlug = params.companySlug;
            this.companiesService
                .getBySlug(companySlug)
                .pipe(catchError(error => throwError(error)))
                .subscribe(company => {
                    this.company = company;
                    this.companiesService
                        .childs(company.id)
                        .subscribe(
                            companies => (this.childCompanies = companies)
                        );
                });
        });
    }

    ngOnInit() : void {
        this.addCompanyStyle();
    }

    register(form: NgForm) {
        Object.keys(form.controls).forEach((key, val) => {
            form.controls[key].markAsDirty();
        });
        if (form.invalid) {
            return;
        }
        this.submitProcess = true;
        const data = form.form.value;
        this.http
            .post(`registration/register`, data)
            .pipe(
                catchError(error => {
                    if (error.status === 422) {
                        switch (error.error.exceptionCode) {
                            case 'invalidRegisterEmailDomain':
                                this.toaster.error(
                                    'Sie dürfen nur unternehmenseigene E-Mail-Adressen nutzen'
                                );
                                break;
                            default:
                                this.toaster.error('Formular überprüfen');
                        }
                    }
                    this.formUtilities.setFormErrors(
                        this.registrationForm,
                        error.error.payload
                    );
                    this.submitProcess = false;
                    return throwError(error);
                })
            )
            .subscribe(res => {
                this.submitProcess = false;
                this.toaster.success('Sie wurden erfolgreich registriert');
                this.toaster.info(
                    'Ihre Registrierung wird von Ihrem Arbeitgeber überprüft. Sie werden per Email benachrichtigt, ' +
                        'sobald die Freischaltung erfolgt ist.',
                    'Genehmigung ausstehend',
                    { timeOut: 10000 }
                );
                this.router.navigate(['login'], {
                    relativeTo: this.route.parent
                });
            });
    }

    onPasswordChange() {
        const password = this.registrationInfo.password;
        if (password) {
            if (!RegistrationComponent.minimum8rule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.numsAndLettersRule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.lowerAndUpperRule(password)) {
                this.isValidPassword = false;
                return;
            }
            if (!RegistrationComponent.specialCharsRule(password)) {
                this.isValidPassword = false;
                return;
            }
            this.isValidPassword = true;
        }
    }

    onPasswordFocus() {
        this.showPasswordTips = true;
    }

    onPasswordBlur() {
        this.showPasswordTips = false;
    }

    addCompanyStyle() {
        let logo = '';
        let color = this.primaryColor;
        this.loginService.loginLogo = this.loginLogo;
        this.route.parent.params.subscribe(params => {
            this.http.get(`company-info/`+params.companySlug).subscribe(
                (res: any) => {
                    if (res.color) {
                        color = res.color;
                    }
                    if (res.logo) {
                        logo = `${environment.apiUrl}/storage${res.logo}`;
                        this.loginService.loginLogo = logo;
                    } else {
                        this.loginService.loginLogo = this.loginLogo;
                    }
                    this.settingsService.updateSettings({
                        ...this.settingsService.settings,
                        color,
                        logo,
                    });
                },
                (error) => {
                    this.toaster.error(error);
                }
            );
        });
    }
}
