import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { User } from '../../shared/models/user';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UsersFactory } from './users.factory';
import { UserInfo } from './models/user-info';
import { Factory } from '../../shared/factory';
import { Page } from '../../shared/models/page';
import { SkipModuleUrl } from '../../core/services/http/api.interceptor';
import { ServerError } from '../../core/exceptions/error';

interface UserListRequest {
    activeUsers: boolean;
    inactiveUsers: boolean;
    pendingUsers: boolean;
    pageSize: number;
    page: number;
}

interface LoginAsResponse {
    portalDomain: string;
    token: { access_token, expires_in };
    user: { company: { slug?: string } };
}

@Injectable()
export class UsersService {
    public userInfo;

    constructor(
        @Inject('MODULE') private module: string,
        private httpClient: HttpClient,
        private usersFactory: UsersFactory,
    ) {
    }

    private static handleError(response: any) {
        if (response instanceof HttpErrorResponse) {
            switch (response.error.exceptionCode) {
                default:
                    return new ServerError();
            }
        } else {
            return response;
        }
    }

    getUserByCompany(id: number | string, email: string = ''): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `company-api/v1/users/userByCompany/${id}`;
        if (this.module === 'system') {
            url = `system/users/userByCompany/${id}`;
        }
        if (email !== '') {
            url += `?searchEmail=${email}`;
        }
        return this.httpClient.get<any>(url, { headers })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    getUserInfo() {
        if (!this.userInfo) {
            this.userInfo = JSON.parse(localStorage.getItem('userObject'));
        }
        return this.userInfo;
    }

    public getPaginatedUsers(request: UserListRequest): Observable<Page<User>> {
        const params = new HttpParams({ fromObject: this.transformRequestToQuery(request) });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'users';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<any>(url, { params, headers })
            .pipe(
                catchError((error) => {
                    const handledError = UsersService.handleError(error);
                    return throwError(handledError);
                }),
                map((response) => {
                    const page = new Factory<Page<User>>(Page).fromObject(response);
                    page.data = new Factory(User).fromArray(page.data);
                    return page;
                })
            );
    }

    all(portalId: number): Observable<User[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/all`;
        if (portalId) {
            url += `?portal_id=${portalId}`;
        }
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<any>(url, { headers })
            .pipe(
                map(users => new Factory(User).fromArray(users))
            );
    }

    get(id: number | string, isSystemAdmin = false, userType: string = ''): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }

        let queryParams = new HttpParams();

        if (isSystemAdmin) {
            queryParams = queryParams.set('isSystemAdmin', '1');
        }
        if (userType) {
            queryParams = queryParams.set('userType', userType);
        }

        return this.httpClient.get<any>(url, { headers: headers, params: queryParams })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    getSystem(id: number | string): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/system/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<any>(url, { headers })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    getUserCompany(id: number | string, isSystemAdmin = false): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/company/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient.get<any>(url, { headers })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => new Factory(User).fromObject(payload)));
    }

    create(user: UserInfo): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'users';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.post<any>(url, user, { headers })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => this.usersFactory.fromObject(payload)));
    }

    loginAs(redirectTo, tokenKey, userId, sameWindow = false) {
        this.httpClient.get<any>(`portal-users/${userId}/login-as`)
            .subscribe(
                (response: LoginAsResponse) => {
                    if (sameWindow) {
                        location.href = this.buildRedirectUrl(redirectTo, tokenKey, response);
                    } else {
                        window.open(
                            this.buildRedirectUrl(redirectTo, tokenKey, response),
                            '_blank');
                    }
                }
            );
    }

    public buildRedirectUrl(
        redirectTo: string,
        tokenKey,
        { portalDomain, token: { access_token, expires_in }, user: { company } }: LoginAsResponse
    ): string {
        let port = location.port ? ':' + location.port : '';
        if (port !== '' && this.module === 'system') {
            port = ':4200';
        }
        const baseUrl = `${location.protocol}//${portalDomain}${port}/redirect?`;
        let params = new HttpParams();
        if (redirectTo.includes('firma') && company && company.slug) {
            params = params.set('redirect_to', `${redirectTo}/${company.slug}/admin/home`);
        } else if (redirectTo.includes('employee') && company && company.slug) {
            params = params.set('redirect_to', `firma/${company.slug}${redirectTo}/home`);
        } else {
            params = params.set('redirect_to', `${redirectTo}`);
        }
        params = params.set('token_key', tokenKey);
        params = params.set('access_token', access_token);
        params = params.set('expire', expires_in);
        return `${baseUrl}${params.toString()}`;
    }

    update(id: number | string, user: UserInfo, isSystemAdmin = false, userType: string = ''): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }

        let queryParams = new HttpParams();
        if (isSystemAdmin) {
            queryParams = queryParams.set('isSystemAdmin', '1');
        }
        if (userType) {
            queryParams = queryParams.set('userType', userType);
        }

        return this.httpClient.put<any>(url, user, { headers: headers, params: queryParams })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => this.usersFactory.fromObject(payload)));
    }

    updateUserCompany(id: number | string, user: User, isSystemAdmin = false): Observable<User> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `users/updateUserCompany/${id}`;
        if (this.module === 'system') {
            url = `system/${url}`;
            if (isSystemAdmin) {
                url += '?isSystemAdmin=1';
            }
        }
        return this.httpClient.put<any>(url, user, { headers })
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => this.usersFactory.fromObject(payload)));
    }

    private transformRequestToQuery(request: UserListRequest) {
        const transformedObject: { [p: string]: string } = {};
        Object.keys(request).forEach((key) => {
            if (typeof request[key] === 'boolean') {
                transformedObject[key] = request[key] ? '1' : '0';
            } else {
                transformedObject[key] = request[key].toString();
            }
        });
        return transformedObject;
    }
}
