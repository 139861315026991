import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
    selector: 'mrc-validation-errors',
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
    host: {
        '[class]': 'className'
    }
})
export class ValidationErrorsComponent implements OnInit, OnChanges {

    @Input() invalid: boolean;
    @Input() errors: ValidationErrors;
    @Input() dirty: boolean;

    @Input() direction: Array<string> = ['lg-right'];

    classList: Array<string>;
    className: string;

    private validationErrors = {
        required: () => 'Das Feld ist erforderlich',
        maxlength: ({ requiredLength }) => `Die maximale Länge beträgt ${requiredLength}`,
        minlength: ({ requiredLength }) => `Die minimale Länge beträgt ${requiredLength}`,
        unique: () => 'Es existiert bereits dieser Wert',
        email: () => 'Die E-Mail ist falsch',
        phone: () => 'Das Telefon ist falsch',
        slug: () => 'Der Slug darf nur Buchstaben, Zahlen und Bindestriche enthalten.',
        pattern: () => 'Falsches Format',
        maxservice: () => 'Der Betrag ist größer als das Maximum für diese Dienstleistung',
        max: ({ max }) => (max) ? `Überschreitung des Maximalwertes (${max})` : 'Überschreitung des Maximalwertes',
    };

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.classList = ['fade form-tooltip'];
        if (this.errors && this.invalid && this.dirty) {
            this.classList.push('show');
        }

        this.direction && this.direction.forEach(dir => this.classList.push('form-tooltip-' + dir));
        this.className = this.classList.join(' ');
    }

    getErrorMessage(errorKey): Array<string> {
        if (this.errors) {
            if (this.validationErrors[errorKey] && this.errors[errorKey]) {
                return this.validationErrors[errorKey](this.errors[errorKey]);
            }
            return this.errors[errorKey];
        }
    }
}
