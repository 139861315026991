import { v4 as uuid } from 'uuid';
import { ProductCategory } from './product-category';
import { HasProductCategory } from './has-product-category';
import { Model } from './model';
import { Mixin } from '../../common/mixin';
import { CurrencyEuroPipe } from '../pipes/euro-currency';
import { CustomPercentPipe } from '../pipes/custom-percent.pipe';

interface RateMixin extends HasProductCategory, Model {}

export enum AmountType {
    Fixed = 'fixed',
    Percentage = 'percentage',
}

export enum RateType {
    Insurance,
    Service,
}

export class Rate extends Mixin<RateMixin>(HasProductCategory, Model) {
    public amount: number;
    public amountType: AmountType;
    public minimum: number;
    public yearlyBudget: number;
    public name: string;
    public id: number;
    public default: boolean;
    public productCategory: ProductCategory;
    public type: RateType;

    constructor() {
        super();
        this.id = uuid();
    }

    public getFactor(): number {
        return this.amount / 100;
    }

    public isDefault(): boolean {
        return this.default;
    }

    setDefault(value: boolean) {
        this.default = value;
    }

    getText() {
        if (this.amount) {
            if (this.amountType === AmountType.Fixed) {
                return `${Number(this.amount)
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')} €`;
            } else {
                return `${this.amount.toString().replace('.', ',')} %`;
            }
        }
        return ' - ';
    }
}
