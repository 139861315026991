import { Portal } from './portal';
import { ContractText } from './contract-text';
import { Text } from './text';

export class AdminSettings {
    public portal: Portal;
    public portal_name: string;
    public color: string;
    public email: string;
    public date_format: string;
    public domain: string;
    public logo: string;
    public leasingable_pdf: string;
    public time_format: string;
    public timezone: string;
    public week_starts_on: string;
    public blind_discount: number;
    public texts: Text[];
    public contractTexts: ContractText[];
    public maintenance_text = '';
    public inspectionEnabled: boolean;
    public enable_bike_shop: boolean;

    constructor (settings) {

        if (settings) {

            Object.keys(settings).forEach((key) => {
                if (settings[key]) {
                    this[key] = settings[key];
                }
            });
        }
    }
}
