import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FormUtilities {
    constructor(
        private toaster: ToastrService,
    ) {
    }

    public setFormErrors(form, errors, prefix = ''): void {

        for (const key in errors) {
            const fieldName = `${prefix}${key}`;
            const msg: Array<string> = [];

            if (typeof errors[key][0] === 'string') {

                if (typeof errors[key] === 'object') {
                    for (const errorText of errors[key]) {
                        msg.push(errorText);
                    }
                } else {
                    msg.push(errors[key]);
                }


                const field = form.controls[fieldName];
                if (field) {
                    field.setErrors({'incorrect': msg.join('<br>')});
                } else {
                    this.toaster.error(msg.join('<br>'));
                }
            } else {

                for (const key2 in errors[key]) {
                    this.setFormErrors(form, errors[key][key2], `${fieldName}_${key2}_`);
                }

            }

        }

    }
}
