import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { SystemAdminRoutes } from './app.routes';
import { MediaMatcher } from '@angular/cdk/layout';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';

import { SharedModule } from '../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '../core/core.module';
import { PublicModule } from '../public/public.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigService } from '../core/services/http/http.service';
import { InterceptSettingsService } from '../core/services/http/intercept-settings.service';
import { SettingsService } from '../core/services/settings/settings.service';
import { ProfileService } from '../core/services/profile/profile.service';
import { SystemApiInterceptor } from '../core/services/http/system-api.interceptor';
import {
    PROTECTED_FALLBACK_PAGE_URI,
    PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { PlatformModule } from '@angular/cdk/platform';
registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule.forRoot(),
        PublicModule,
        BrowserModule,
        BrowserAnimationsModule,
        SystemAdminRoutes,
        SharedModule,
        PlatformModule,
        MatProgressBarModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
    ],
    providers: [
        { provide: 'MODULE', useValue: 'system' },
        { provide: 'MODULE_TOKEN', useValue: 'access_token' },
        { provide: 'MODULE_URL', useValue: `/system-api` },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SystemApiInterceptor,
            multi: true,
        },
        InterceptSettingsService,
        HttpConfigService,
        SettingsService,
        ProfileService,
        MediaMatcher,
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: `/admin` },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: `/login` },
    ],
    bootstrap: [AppComponent],
})
export class SystemAdminAppModule {}
