import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private errorMessage: string = '';
    private errorCode: string = '404';

    public setErrorMessage(message: string): void {
        this.errorMessage = message;
    }

    public getErrorMessage(): string {
        return this.errorMessage;
    }

    public setErrorCode(errorCode: string): void {
        this.errorCode = errorCode;
    }

    public getErrorCode(): string {
        return this.errorCode;
    }

    public clearError(): void {
        this.errorCode = '';
        this.errorMessage = '';
    }
}
