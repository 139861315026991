import { ApplicationRef, NgModuleRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createNewHosts } from '@angularclass/hmr';

import { SystemAdminAppModule } from './system-admin-app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

function bootstrapWithHmr(ngModule: NgModuleRef<any>) {
    module['hot'].accept();
    module['hot'].dispose(() => {
        const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
        const elements = appRef.components.map(c => c.location.nativeElement);
        const makeVisible = createNewHosts(elements);

        ngModule.destroy();

        makeVisible();
    });
}

async function main () {
    try {
        const ngModule = await platformBrowserDynamic().bootstrapModule(SystemAdminAppModule);
        if (module['hot']) {
            bootstrapWithHmr(ngModule);
        }
    } catch (error) {
        console.error(error);
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', main);
} else {
    main();
}
