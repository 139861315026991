<div class="layout-unauthorized">

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12">
                <div class="stepper-container">
                    <div class="step">
                        <div class="circle bg-primary">1</div>
                        <div class="label">Daten eingeben und absenden</div>
                    </div>
                    <div class="divider .d-none .d-md-block"></div>
                    <div class="step">
                        <div class="circle bg-primary">2</div>
                        <div class="label">Freigabe durch den Arbeitgeber</div>
                    </div>
                    <div class="divider .d-none .d-md-block"></div>
                    <div class="step">
                        <div class="circle bg-primary">3</div>
                        <div class="label">Login</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">

                <div class="card">
                    <div class="card-body">
                        <div class="logo">
                            <img [src]="loginService.loginLogo" alt="">
                        </div>
                        <h2 class="title">Registrierung</h2>

                        <div class="row justify-content-center">
                            <div class="col-sm-10 text-danger" [innerHtml]="errorMessage" *ngIf="errorMessage"></div>

                            <form class="col-sm-10" #registrationForm="ngForm" novalidate (ngSubmit)="register(registrationForm)">
                                <fieldset class="form-group">
                                    <div class="text-center">
                                        <small *ngIf="childCompanies?.length > 1">Bitte beachten Sie, sich für das korrekte Unternehmen zu registrieren.</small>
                                    </div>
                                    <mrc-form-item-field
                                        [direction]="['bottom']"
                                        label="Ich bin in diesen Unternehmen beschäftigt:"
                                        [control]="registrationForm.controls?.company_id"
                                        *ngIf="childCompanies?.length > 1">
                                    <ng-select
                                            [virtualScroll]="true"
                                            bindValue="id"
                                            bindLabel="name"
                                            [items]="childCompanies"
                                            [clearable]="false"
                                            name="company_id"
                                            class="form-control ng-select form-element"
                                            [(ngModel)]="registrationInfo.companyId"
                                            [searchable]="true"
                                            placeholder="Bitte hier auswählen"
                                            required></ng-select>
                                    </mrc-form-item-field>

                                    <mrc-form-item-field
                                        label="E-Mail"
                                        [id]="'email'"
                                        [control]="registrationForm.controls?.email">
                                        <input class="form-control form-control-bg "
                                               id="email"
                                               [(ngModel)]="registrationInfo.email"
                                               (ngModelChange)="onPasswordChange()"
                                               placeholder="E-Mail"
                                               name="email" required>
                                    </mrc-form-item-field>

                                    <div class="row adjust-half-row">
                                        <mrc-form-item-field
                                            label="Vorname"Freigabe durch den Arbeitgeber
                                            class=" col-6"
                                            [id]="'first_name'"
                                            [control]="registrationForm.controls?.first_name">
                                            <input class="form-control form-control-bg "
                                                   id="first_name"
                                                   [(ngModel)]="registrationInfo.first_name"
                                                   (ngModelChange)="onPasswordChange()"
                                                   placeholder="Vorname"
                                                   name="first_name" required>
                                        </mrc-form-item-field>

                                        <mrc-form-item-field
                                            label="Nachname"
                                            class=" col-6"
                                            [id]="'last_name'"
                                            [control]="registrationForm.controls?.last_name">
                                            <input class="form-control form-control-bg "
                                                   id="last_name"
                                                   [(ngModel)]="registrationInfo.last_name"
                                                   (ngModelChange)="onPasswordChange()"
                                                   placeholder="Nachname"
                                                   name="last_name" required>
                                        </mrc-form-item-field>
                                    </div>

                                    <mrc-form-item-field class="mb-0" label="Pers.Nr." [control]="registrationForm.controls?.employee_number"
                                        *ngIf="company?.required_personal_number">
                                        <input class="form-control form-control-bg " type="text" [(ngModel)]="registrationInfo.employee_number"
                                            placeholder="MIT-0022" name="employee_number" required>
                                    </mrc-form-item-field>

                                    <mrc-form-item-field
                                        label="Passwort"
                                        [id]="'password'"
                                        [control]="registrationForm.controls?.password">
                                        <input class="form-control form-control-bg "
                                               id="password"
                                               type="password"
                                               [(ngModel)]="registrationInfo.password"
                                               (ngModelChange)="onPasswordChange()"
                                               (focus)="onPasswordFocus()"
                                               (blur)="onPasswordBlur()"
                                               placeholder="Passwort"
                                               name="password" required password>
                                    </mrc-form-item-field>

                                    <div class="password-validator-container bg-primary" *ngIf="!registrationForm.controls?.password?.pristine && !registrationForm.controls?.password?.valid">
                                        <div class="box">
                                            <h4 class="title">Sicherheitsanforderung für Kennwörter:</h4>
                                            <p class="rule">
                                                    - 8-15 Zeichen<br>
                                                    - mind. 1 Großbuchstabe<br>
                                                    - mind. 1 Kleinbuchstabe<br>
                                                    - mind. 1 Zahl<br>
                                                    - mind. 1 Sonderzeichen<br>
                                            </p>
                                        </div>
                                    </div>

                                    <mrc-form-item-field
                                        label="Passwort wiederholen"
                                        [id]="'password'"
                                        [control]="registrationForm.controls?.password_confirmation">
                                        <input class="form-control form-control-bg "
                                               type="password"
                                               [(ngModel)]="registrationInfo.password_confirmation"
                                               (ngModelChange)="onPasswordChange()"
                                               (focus)="onPasswordFocus()"
                                               (blur)="onPasswordBlur()"
                                               name="password_confirmation"
                                               placeholder="Passwort wiederholen"
                                               required
                                               validateEqual="password">
                                    </mrc-form-item-field>

                                    <div class="form-group-btn">
                                        <button type="submit" class="btn btn-primary btn-block" [disabled]="submitProcess">
                                            <span *ngIf="!submitProcess">Registrierung absenden</span>
                                            <i class="fal fa-sync-alt" *ngIf="submitProcess">

                                            </i>
                                        </button>
                                    </div>
                                    <div class="row mt-2 justify-content-center">
                                        <small>Sind Sie bereits registriert? Dann klicken Sie
                                            <a [routerLink]="'../login'" class="link-inherit light">hier</a>
                                        </small>
                                    </div>
                                    <div class="row mt-3 justify-content-center">
                                        <small><a [routerLink]="'../password/forgot-password'" class="link-inherit light">Passwort vergessen?</a></small>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
