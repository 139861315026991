import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Services
import { AuthenticationModule } from './services/auth/auth.module';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from './services/login/login.service';
import { AuthenticationService } from './services/auth/auth.service';
import { AUTH_SERVICE, AuthModule } from 'ngx-auth';
import { AuthSettingsService } from './services/auth/auth-settings.service';
import { TokenStorageService } from './services/auth/token-storage.service';
import { FaviconService } from './services/settings/favicon.service';
import { MenuService } from '../layout/sidebar/menu.service';
import { SidebarSettingsService } from '../layout/sidebar/sidebar-settings.service';
import { HttpClientModule } from '@angular/common/http';
import { FormUtilities } from './forms/form-utilities';

@NgModule({
    imports: [
        CommonModule,
        AuthenticationModule,
        HttpClientModule,
        AuthModule,
    ],
    declarations: [],
    exports: [],
    providers: [
        FormUtilities,
    ]
})
export class CoreModule {
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                CookieService,
                LoginService,
                MenuService,
                SidebarSettingsService,
                FaviconService,
                TokenStorageService,
                AuthSettingsService,
                {
                    provide: AUTH_SERVICE,
                    useClass: AuthenticationService
                },
            ]
        };
    }
}
