export class Page<T> {
    pageSize = 20;

    data: T[];

    page = 1;

    totalElements = 0;

    totalPages = 0;

    pageNumber = 0;
}
