import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminSettings } from '../../shared/models/admin-settings';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceService {
    private SkipModuleUrl = 'X-Skip-Module-Url';

    constructor(private readonly httpClient: HttpClient) {}

    getSettings(): Observable<AdminSettings> {
        const headers = new HttpHeaders().set(this.SkipModuleUrl, '');
        return this.httpClient.get<AdminSettings>(`system-api/settings`, {
            headers
        });
    }
}
