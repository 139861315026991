<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img [src]="loginService.loginLogo" alt="">
                </div>

                <div class="card">
                    <div class="card-body">
                        <h1 class="title">{{ title }}</h1>
                        <p class="title-description" *ngIf="submitted === false">Geben Sie Ihre E-Mail-Adresse ein</p>

                        <div class="row justify-content-center">
                            <form *ngIf="submitted === false" class="col-sm-8" #forgotPasswordForm="ngForm" novalidate (ngSubmit)="forgotPassword(forgotPasswordForm)">
                                <div
                                    class="form-control-holder form-group">
                                    <input type="email" class="form-control" placeholder="E-Mail" name="email" [(ngModel)]="email" required email>
                                    <mrc-validation-errors [invalid]="forgotPasswordForm.controls?.email?.invalid"
                                                           [dirty]="forgotPasswordForm.controls?.email?.dirty"
                                                           [errors]="forgotPasswordForm.controls?.email?.errors"
                                                           [direction]="['md-right']"></mrc-validation-errors>
                                </div>
                                <div class="form-control-holder form-group" *ngIf="childCompanies?.length > 1">
                                    <ng-select
                                        [virtualScroll]="true"
                                        bindValue="id"
                                        bindLabel="name"
                                        [items]="childCompanies"
                                        [clearable]="false"
                                        name="company_id"
                                        class="form-control ng-select form-element"
                                        [(ngModel)]="companyId"
                                        [searchable]="true"
                                        placeholder="Bitte wählen Sie eine Option"
                                        required></ng-select>
                                </div>
                                <div class="form-group-btn">
                                    <button type="submit" class="btn btn-primary btn-block" [disabled]="!forgotPasswordForm.valid">Einreichen</button>
                                </div>
                            </form>

                            <div *ngIf="submitted" class="col-sm-10 text-center text-big mt-4 mb-4">
                                Vielen Dank!
                                <br>Falls ein Konto mit der angegebenen E-Mail-Adresse existiert, haben wir einen Link zum Zurücksetzen des Passworts gesendet.
                                <br>Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen im E-Mail, um Ihr Passwort zurückzusetzen.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
