import { PortalLeasingCondition } from './portal-leasing-condition';
import { InsuranceRate } from './insurance-rate';
import { ServiceRate } from './service-rate';
import { LeasingCondition } from './leasing-condition';
import { CityInterface } from './city.interface';
import { Status } from './status';
import { Model } from './model';
import { HasLeasingSettings } from './has-leasing-settings';
import { Mixin } from '../../common/mixin';
import { CoverageTypes } from './company';
import { DiscountMode } from '../models/supplier.interface';
import { PortalType } from '@shared/models/portal-settings.interface';

export enum PortalStatus {
    Active = 3,
    Inactive = 4,
}

interface PortalMixin extends Model, HasLeasingSettings {}

export class Portal extends Mixin<PortalMixin>(Model, HasLeasingSettings) {
    public endpoint = 'portals';
    public id: number;
    public partnerId: number;
    public logo: string | Blob;
    public color: string;
    public name: string;
    public domain: string;
    public baseDomain: string;
    public subdomain: string;
    public status: Status;
    public leasingablePdf: string | Blob;
    public adminEmail: string;
    public adminFirstName: string;
    public adminLastName: string;
    public companyAddress: string;
    public companyCity: CityInterface;
    public companyName: string;
    public companyVat: string;
    public companyPhoneNumber: string;
    public policyPdf: string | Blob;
    public servicePdf: string | Blob;
    public policy: string;
    public autoresponderText: string;
    public automaticCreditNote: boolean;
    public inspectionAutomaticCreditNote: boolean;
    public allowEmployeeOfferCreation: boolean;
    public imprintPdf: string | Blob;
    public imprint: string | Blob;
    public leasingRateSubsidy = false;
    public leasingRateSubsidyType = CoverageTypes.PERCENTAGE;
    public leasingRateSubsidyAmount = 100;
    public insuranceRateSubsidy = false;
    public insuranceRateSubsidyType = CoverageTypes.PERCENTAGE;
    public insuranceRateSubsidyAmount = 100;
    public serviceRateSubsidy = false;
    public serviceRateSubsidyType = CoverageTypes.PERCENTAGE;
    public serviceRateSubsidyAmount = 100;
    public creditNoteDiscount: number;
    public discountMode: DiscountMode;
    public inspectionEnabled: boolean;
    public productCategoryIds: number[];
    public enableGovecs: boolean;
    public enableDelivery: boolean;
    public enableSubSuppliers: boolean;
    public enableGovecsCalculator: boolean;
    public configuratorCid: string;
    public govecsMenuText: string;
    public govecsMenuUrl: string;
    public partnerNumber: string;
    public portalType = PortalType;
    public portalEmailAddress: string;
    public portalSenderEmailAddress: string;
    public apiExportEnabled: boolean;
    public mppOfferExpiryInDays: number;

    isActive() {
        return this.status.id === PortalStatus.Active;
    }

    addLeasingCondition(leasingCondition: PortalLeasingCondition): void {
        if (
            !this.getDefaultLeasingCondition(leasingCondition.productCategory)
        ) {
            leasingCondition.setDefault(true);
        }
        super.addLeasingCondition(leasingCondition);
    }

    setDefaultLeasing(leasing: LeasingCondition) {
        const defaultLeasing = this.getDefaultLeasingCondition(
            leasing.productCategory
        );
        if (defaultLeasing) {
            defaultLeasing.setDefault(false);
        }
        this.leasingConditions
            .find((item) => item.id === leasing.id)
            .setDefault(true);
    }

    setDefaultInsuranceRate(rate: InsuranceRate) {
        const defaultInsuranceRate = this.getDefaultInsuranceRate(
            rate.productCategory
        );
        if (defaultInsuranceRate) {
            defaultInsuranceRate.setDefault(false);
        }
        this.insuranceRates
            .find((item) => item.id === rate.id)
            .setDefault(true);
    }

    setDefaultServiceRate(rate: ServiceRate) {
        const defaultServiceRate = this.getDefaultServiceRate(
            rate.productCategory
        );
        if (defaultServiceRate) {
            defaultServiceRate.setDefault(false);
        }
        this.serviceRates.find((item) => item.id === rate.id).setDefault(true);
    }
}
