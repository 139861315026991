import { Component, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginService } from '../../core/services/login/login.service';
import { Subscription, throwError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ForbiddenError } from '../../core/exceptions/error';
import { AuthSettingsService } from '../../core/services/auth/auth-settings.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Company } from '../../shared/models/company';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '@core/services/loading/loading.service';
import { HttpParams } from '@angular/common/http';
import { PortalService } from '../../portals/portal.service';
import { environment } from '../../environments/environment';
import { UsersService } from '@app/users/users.service';
import { User } from '@shared/models/user';

interface LoginAsResponse {
    portalDomain: string;
    token: { access_token, expires_in };
    user: User;
}

@Component({
    selector: 'mrc-login-dd',
    templateUrl: './login-dd.component.html',
    styleUrls: ['./login-dd.component.scss'],
})
export class LoginDeutscheDienstradComponent implements OnInit {

    @ViewChild('loginForm') loginForm: HTMLFormElement;
    @ViewChild('subcompanyModal') subcompanyModal: TemplateRef<any>;

    public errorMessage: string;
    public submitProcess = false;
    public email;
    public password;
    public companyId;
    public loginSubscription: Subscription;
    public childCompanies: Company[];
    public tokenKey;
    public user;
    public response : LoginAsResponse;
    public portalLogoUrl;

    constructor(
        @Inject('MODULE') public module: string,
        public authSettings: AuthSettingsService,
        public loginService: LoginService,
        public route: ActivatedRoute,
        private toaster: ToastrService,
        public dialog: MatDialog,
        public loadingService: LoadingService,
        private http: HttpClient,
        public usersService: UsersService,
        private portalService: PortalService,
    ) {
    }

    ngOnInit() {
        this.loadingService.firstLoading = false;
        this.authSettings.updatePath('/');
        this.portalService.getCurrentPortal().subscribe((portal) => {
            this.portalLogoUrl=`${environment.apiUrl}/storage/${portal.logo}`;
        });
    }

    login(form: NgForm) {
        this.submitProcess = true;
        const data = form.form.value;
        this.loginSubscription = this.http.post(`unilogin`,data)
            .pipe(catchError(error => {
                this.submitProcess = false;
                if (error instanceof ForbiddenError) {
                    this.toaster.error(error.message);
                } else {
                    this.toaster.error(error.error.message);
                }
                return throwError(error);
            }))
            .subscribe(async (res : any) => {
                this.response = res;
                this.user = res.user;
                this.handleLoginWithoutSubmenu()
                this.submitProcess = false;
            });
    }

    public handleLoginWithoutSubmenu() {
        switch (true) {
            case this.user.roles.includes('Company Admin'):
                return this.loginAsCompany();
            case this.user.roles.includes('Employee'):
                return this.loginAsEmployee();
            case this.user.roles.includes('Supplier Admin'):
                return this.loginAsSupplier();
        }
    }

    loginAsSupplier() {
       // alert('SUPPLIER');
        location.href = this.buildRedirectUrl('/lieferanten', 'supplier_access_token', this.response);
    }

    loginAsCompany() {
      //  alert('CA');
        location.href = this.buildRedirectUrl('/firma', 'company_access_token', this.response);
    }

    loginAsEmployee() {
      //  alert('EMPLOYEE');
        location.href = this.buildRedirectUrl('/employee', 'employee_access_token', this.response);
    }

    showRoleChooser(roles) {

    }

    private buildRedirectUrl(
        redirectTo: string,
        tokenKey,
        { portalDomain, token: { access_token, expires_in }, user: { company } }: LoginAsResponse
    ): string {
        let port = location.port ? ':' + location.port : '';
        if (port !== '' && this.module === 'system') {
            port = ':4200';
        }
        const baseUrl = `${location.protocol}//${portalDomain}${port}/redirect?`;
        let params = new HttpParams();
        if (redirectTo.includes('firma') && company && company.slug) {
            params = params.set('redirect_to', `${redirectTo}/${company.slug}/admin/home`);
        } else if (redirectTo.includes('employee') && company && company.slug) {
            params = params.set('redirect_to', `firma/${company.slug}${redirectTo}/home`);
        } else {
            params = params.set('redirect_to', `${redirectTo}`);
        }
        params = params.set('token_key', tokenKey);
        params = params.set('access_token', access_token);
        params = params.set('expire', expires_in);
        return `${baseUrl}${params.toString()}`;
    }

    ngOnDestroy() {
        this.errorMessage = '';
    }

}

