import { Model } from './model';

export class FinancialInformation extends Model
{
    gross_salary: number;
    tax_class: number;
    tax_classes: Array<number> = [];
    pension_insurance_required: boolean;
    state: string;
    states: Array<string> = [];
    numberOfChildrenAllowancesList: Array<number> = [];
    health_insurance_type: boolean;
    church_tax_required: boolean;
    number_of_children_allowances: number;
    age_over_twenty_three_years: boolean;
    employers_contribution_rate: number;
    employers_contribution_rate_in_percentage: number;
    health_insurance_supplementary_contribution_rate: number;
    birth_year: number;

    constructor(data?: Partial<FinancialInformation>) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    get employersContributionRateInEuro(): number {
        return this.employers_contribution_rate ? this.employers_contribution_rate / 100 : 0;
    }
}
