import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SidebarSettingsService {
    public logoSettings = new ReplaySubject<object>();

    public updateSettings(value) {
        this.logoSettings.next(value);
    }
}
