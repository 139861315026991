import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[required][formControlName],[required][formControl],[required][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: RequiredDirective,
        multi: true
    }]
})
export class RequiredDirective implements Validator {

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const fieldValue = control.value;

        if (!/^\S{1,}.*$/gm.test(String(fieldValue))) { // multiline regex
            return {
                required: 'Dieses Feld ist erforderlich'
            };
        }

        return null;
    }

}
