import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import { Portal } from '../shared/models/portal';
import { PortalFactory } from './portal-factory';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { catchError, map, publishReplay, refCount, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { SkipModuleUrl } from '../core/services/http/api.interceptor';
import { Factory } from '../shared/factory';

@Injectable({
    providedIn: 'root'
})
export class PortalService {
    private currentPortalObservable: Observable<Portal>;

    constructor(private httpClient: HttpClient,
                private portalFactory: PortalFactory,
                private toaster: ToastrService,
                ) {
    }

    public get(id): Observable<Portal> {
        return this.httpClient.get(`portals/${id}`)
            .pipe(
                map(response => <Portal>this.portalFactory.fromResponse(response))
            );
    }

    public all(): Observable<Portal[]> {
        return this.httpClient.get<any>('portals/all')
            .pipe(
                catchError((error) => throwError(error)),
                map(({ payload }) => new Factory(Portal).fromArray(payload))
            );
    }

    public create(portal: Portal): Observable<Portal> {
        return this.httpClient.post('portals',
        {
            ...portal,
            logo: null,
            leasingablePdf: null,
            servicePdf: null,
            policyPdf: null,
            imprintPdf: null
        }).pipe(
                map(response => <Portal>this.portalFactory.fromResponse(response)),
                switchMap(this.handleFileUploads(portal)),
                tap(() => this.toaster.success('Das Portal wurde erfolgreich hinzugefügt'))
            );
    }

    public update(portal: Portal): Observable<Portal> {
        return this.httpClient.put(`portals/${portal.id}`, {
            ...portal,
            logo: (typeof portal.logo === 'object') ? null : portal.logo,
            leasingablePdf: (typeof portal.leasingablePdf === 'object') ? null : portal.leasingablePdf,
            servicePdf: (typeof portal.servicePdf === 'object') ? null : portal.servicePdf,
            policyPdf: (typeof portal.policyPdf === 'object') ? null : portal.policyPdf,
            imprintPdf: (typeof portal.imprintPdf === 'object') ? null : portal.imprintPdf
        })
            .pipe(
                map(response => <Portal>this.portalFactory.fromResponse(response)),
                switchMap(this.handleFileUploads(portal)),
                tap(() => this.toaster.success('Das Portal wurde erfolgreich aktualisiert'))
            );
    }

    public getCurrentPortal(): Observable<Portal> {
        if (!this.currentPortalObservable) {
            const headers = new HttpHeaders().append(SkipModuleUrl, '');
            this.currentPortalObservable = this.httpClient.get(`portal-info`, { headers })
            .pipe(
                map(response => {
                    return this.portalFactory.fromObject(response);
                }),
                publishReplay(1),
                refCount(),
            );
        }
        return this.currentPortalObservable;
    }

    public getCurrentPortalPromise(): Promise<Portal> {
        if (!this.currentPortalObservable) {
            const headers = new HttpHeaders().append(SkipModuleUrl, '');
            this.currentPortalObservable = this.httpClient.get(`portal-info`, { headers })
            .pipe(
                map(response => {
                    return this.portalFactory.fromObject(response);
                }),
                publishReplay(1),
                refCount(),
            );
        }
        return this.currentPortalObservable.toPromise();
    }

    public getCurrentPortalNonCached(): Observable<Portal> {
        const headers = new HttpHeaders().append(SkipModuleUrl, '');
        return this.httpClient.get<Portal>(`portal-info`, { headers })
            .pipe(
                map(portal => this.portalFactory.fromObject(portal))
            );
    }

    private handleFileUploads(portal: Portal) {
        return (portalResponse: Portal) => {
            let observable = of(Object.assign(portal, { id: portalResponse.id }));
            if (portal.logo instanceof File) {
                observable = this.pipeToLogoObservable(observable);
            }
            if (portal.leasingablePdf instanceof File) {
                observable = this.pipeToLeasingablePdfObservable(observable);
            }
            if (portal.servicePdf instanceof File) {
                observable = this.pipeToServicePdfObservable(observable);
            }
            if (portal.policyPdf instanceof File) {
                observable = this.pipeToPolicyPdfObservable(observable);
            }
            if (portal.imprintPdf instanceof File) {
                observable = this.pipeToImprintPdfObservable(observable);
            }
            return observable.pipe(
                map(portalAfterUploads => {
                        return Object.assign(portalResponse, {
                            logo: portalAfterUploads.logo,
                            leasingablePdf: portalAfterUploads.leasingablePdf,
                            servicePdf: portalAfterUploads.servicePdf,
                            policyPdf: portalAfterUploads.policyPdf,
                            imprintPdf: portalAfterUploads.imprintPdf
                        });
                    }
                ),
            );

        };
    }

    private pipeToLogoObservable(observable: Observable<Portal>) {
        return observable.pipe(
            switchMap(portal => this.uploadLogo(`portals/${portal.id}/files`, portal.logo)
                .pipe(
                    map(logo => {
                        portal.logo = logo;
                        return portal;
                    })
                )),
        );
    }

    private pipeToLeasingablePdfObservable(observable: Observable<Portal>) {
        return observable.pipe(
            switchMap(portal => this.uploadLeasingablePdf(`portals/${portal.id}/files`, portal.leasingablePdf)
                .pipe(
                    map(leasingablePdf => {
                        portal.leasingablePdf = leasingablePdf;
                        return portal;
                    })
                )),
        );
    }

    private pipeToServicePdfObservable(observable: Observable<Portal>) {
        return observable.pipe(
            switchMap(portal => this.uploadServicePdf(`portals/${portal.id}/files`, portal.servicePdf)
                .pipe(
                    map(servicePdf => {
                        portal.servicePdf = servicePdf;
                        return portal;
                    })
                )),
        );
    }

    private pipeToPolicyPdfObservable(observable: Observable<Portal>) {
        return observable.pipe(
            switchMap(portal => this.uploadPolicyPdf(`portals/${portal.id}/files`, portal.policyPdf)
                .pipe(
                    map(policyPdf => {
                        portal.policyPdf = policyPdf;
                        return portal;
                    })
                )),
        );
    }

    private pipeToImprintPdfObservable(observable: Observable<Portal>) {
        return observable.pipe(
            switchMap(portal => this.uploadImprintPdf(`portals/${portal.id}/files`, portal.imprintPdf)
                .pipe(
                    map(imprintPdf => {
                        portal.imprintPdf = imprintPdf;
                        return portal;
                    })
                )),
        );
    }

    private uploadLogo(url: string, file: string | Blob) {
        const formData = new FormData();
        formData.append('logo', file);
        return this.upload(url, formData)
            .pipe(
                map(response => response.payload.logo)
            );
    }

    private uploadLeasingablePdf(url: string, file: string | Blob) {
        const formData = new FormData();
        formData.append('leasingablePdf', file);
        return this.upload(url, formData)
            .pipe(
                map(response => response.payload.leasingablePdf)
            );
    }

    private uploadServicePdf(url: string, file: string | Blob) {
        const formData = new FormData();
        formData.append('servicePdf', file);
        return this.upload(url, formData)
            .pipe(
                map(response => response.payload.servicePdf)
            );
    }

    private uploadPolicyPdf(url: string, file: string | Blob) {
        const formData = new FormData();
        formData.append('policyPdf', file);
        return this.upload(url, formData)
            .pipe(
                map(response => response.payload.policyPdf)
            );
    }

    private uploadImprintPdf(url: string, file: string | Blob) {
        const formData = new FormData();
        formData.append('imprintPdf', file);
        return this.upload(url, formData)
            .pipe(
                map(response => response.payload.imprintPdf)
            );
    }

    private upload(url: string, formData: FormData) {
        return this.httpClient.post<any>(url, formData)
            .pipe(
                catchError((error) => {
                    this.toaster.error('Fehler beim Hochladen des Logos');
                    console.error(error);
                    return throwError(error);
                })
            );
    }
}
