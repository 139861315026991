import { Model } from './model';
import { Supplier } from './supplier';
import { PermissionModel } from '../../permission.model';
import { Portal } from './portal';

enum ROLES {
    SYSTEM = 1,
    PORTAL = 2,
    COMPANY = 3,
}

export class UserRoleInfo extends Model {
    companyEnabled: boolean;
    company: any;
    portal: any;
    supplierEnabled: boolean;
    supplier: Supplier;
    portalEnabled: boolean;
    portalId: number;
    isPortalAdmin: boolean;
    hasEditPermission: boolean;
    permissions: PermissionModel[];
    isSystemAdmin: boolean;
    isSubSupplier: boolean;
    parentSupplier: Supplier;
    isCalculatorPartner: boolean;

    constructor() {
        super();
        this.companyEnabled = false;
        this.supplierEnabled = false;
        this.isPortalAdmin = false;
        this.hasEditPermission = false;
        this.portalEnabled = false;
        this.isSystemAdmin = false;
        this.company = new Object();
        this.supplier = new Supplier();
        this.isSubSupplier = false;
        this.parentSupplier = null;
    }

    set role(rol: number) {
        if (rol === ROLES.SYSTEM) {
            this.isSystemAdmin = true;
            this.isPortalAdmin = false;
            this.companyEnabled = false;
        } else if (rol === ROLES.PORTAL) {
            this.isPortalAdmin = true;
            this.isSystemAdmin = false;
            this.companyEnabled = false;
        } else {
            this.companyEnabled = true;
            this.isSystemAdmin = false;
            this.isPortalAdmin = false;
        }
    }

    setRelations() {
        if (!this.company) {
            this.company = new Object();
        }
        if (!this.supplier) {
            this.supplier = new Supplier();
        }
    }

    hasCompany() {
        return this.company && this.company.id > 0;
    }

    hasSupplier() {
        return this.supplier && this.supplier.id > 0;
    }

    hasPortal() {
        return this.portalId > 0;
    }
}
