import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, ObservableInput, of, throwError} from 'rxjs';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import {LoadingService} from '../loading/loading.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {ForbiddenError} from '../../exceptions/error';
import {TokenStorageService} from '../auth/token-storage.service';
import {MaintenanceService} from '../../../public/maintenance/maintenance.service';

export const SkipModuleUrl = 'X-Skip-Module-Url';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        public loadingService: LoadingService,
        public cookieService: CookieService,
        public tokenStorageService: TokenStorageService,
        @Optional() @Inject('MODULE_TOKEN') public tokenKey,
        @Optional() @Inject('MODULE') public module,
        @Optional() @Inject('MODULE_URL') public url,
        public router: Router,
        private authService: AuthenticationService,
        private toaster: ToastrService,
        private maintenanceService: MaintenanceService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (window.location.hostname === 'pexco.meinfirmenroller.services') {
            window.location.href = window.location.protocol + '//deutsche-dienstrad.meinfirmenroller.services' + window.location.pathname;
        }
        const requestWithHeaders = this.getRequestWithHeaders(req);
        this.loadingService.queueRequest(requestWithHeaders);
        return this.handleRequest(requestWithHeaders, next);
    }

    private handleRequest(request, handler): Observable<HttpEvent<any>> {
        const maintenance = this.module === 'system' ? false : false; // this seems always false, check if this is intended

        return handler
            .handle(request)
            .pipe(
                tap({
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            // console.log('HttpResponse::event =', event); // Log successful responses
                        }
                    },
                    // error: (error) => console.error('Error::event =', error) // Log errors
                }),
                catchError<any, ObservableInput<any>>(this.handleError.bind(this)),
                finalize(() => {
                    this.loadingService.removeFromQueue(request);
                    if (maintenance) {
                        this.router.navigate(['maintenance']);
                    }
                }),
            );
    }

    private handleError(errorResponse: HttpErrorResponse) {
        switch (errorResponse.status) {
            case 403:
                return this.handleForbiddenError(errorResponse).pipe(
                    mergeMap(error => throwError(error))
                );
            case 401:
                if (errorResponse.error.exceptionCode === 'invalidToken') {
                    this.authService.logout();
                    this.toaster.error(
                        'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.'
                    );
                }
                break;
            case 404:
                console.error(errorResponse);
                break;
            case 500:
                if (errorResponse.error instanceof Blob && errorResponse.error.type === 'application/json') {
                    return new Observable(observer => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent) => {
                            try {
                                // Assert the type of e.target to FileReader
                                const target = e.target as FileReader;
                                const jsonResponse = JSON.parse(target.result as string);
                                this.displayErrorMessage(jsonResponse);
                                observer.error(new HttpErrorResponse({
                                    ...errorResponse,
                                    error: jsonResponse
                                }));
                            } catch (error) {
                                observer.error(errorResponse);
                            }
                        };
                        reader.onerror = () => {
                            observer.error(errorResponse);
                        };
                        reader.readAsText(errorResponse.error);
                    });
                } else {
                    this.displayErrorMessage(errorResponse.error);
                    return throwError(errorResponse);
                }
        }

        return throwError(errorResponse || {});
    }


    private displayErrorMessage(errorData: any): void {
        const message = errorData.message || 'Ein unbekannter Fehler ist aufgetreten.';
        let additionalInfo = '';

        if (errorData.payload) {
            additionalInfo = Object.entries(errorData.payload)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
        }

        const fullErrorMessage = additionalInfo ? `${message} (Details: ${additionalInfo})` : message;
        this.toaster.error(fullErrorMessage);
    }

    private getRequestWithHeaders(req: HttpRequest<any>) {
        let headers = req.headers.set('Accept', 'application/json');
        headers = headers.set('X-From-Benefit-Portal', '');
        headers = headers.set('X-Benefit-Portal-Module', this.module || '');
        if (this.tokenStorageService.checkAccessToken(this.tokenKey)) {
            headers = headers.set(
                'Authorization',
                `Bearer ${this.cookieService.get(this.tokenKey)}`
            );
        }
        let url = '';
        url = `${environment.apiUrl}`;
        if (!req.headers.has(SkipModuleUrl) && this.url) {
            url = `${url}${this.url}`;
        }
        url = `${url}/${req.url}`;
        return req.clone({
            url,
            headers
        });
    }

    private handleForbiddenError(error: HttpErrorResponse): Observable<any> {
        if (error.error.message === 'Wrong route') {
            return of(
                this.router.navigateByUrl(`${error.error.payload.redirect_to}`)
            );
        } else if (
            error.status === 403 &&
            error.error.payload.error === 'auth.inactive_company'
        ) {
            return this.authService.isAuthorized().pipe(
                map(authorized => {
                    if (authorized) {
                        this.toaster.error(
                            'Die Firma ist aktuell inaktiv. Bitte informieren Sie den Firmenadministrator'
                        );
                        this.authService.logout();
                    }
                    return new ForbiddenError();
                })
            );
        } else {
            return this.authService.isAuthorized().pipe(
                map(authorized => {
                    if (!authorized  && !this.authService.isLoginOrRegisterUrl()) {
                        this.authService.logout();
                    }
                    return new ForbiddenError();
                })
            );
        }
    }
}
