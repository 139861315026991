import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ConfirmModalMaterialComponent } from './confirm/confirm-modal-material.component';
import { AlertModalComponent } from './alert/alert-modal.component';
import { MaterialModule } from '../portal-app/material.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [
        ConfirmModalComponent,
        ConfirmModalMaterialComponent,
        AlertModalComponent,
    ],
    exports: [
        ConfirmModalComponent,
        ConfirmModalMaterialComponent,
        AlertModalComponent,
        MaterialModule
    ]
})
export class ModalsModule {
}
