import { Company } from './company';
import { Model } from './model';
import { PermissionModel } from '../../permission.model';
import { HasMany } from '../../core/relations/has-many';
import { Dinero } from 'dinero.js';
import * as DineroFactory from 'dinero.js';
import { Audit } from './audit';
import { HasOne } from '../../core/relations/has-one';
import { UserRoleInfo } from './user-role-info';
import { Supplier } from './supplier';
import {Portal} from "@shared/models/portal";

export enum SubsidyTypes {
    FIXED = 'fixed',
    PERCENTAGE = 'percentage',
}

export enum UserStatus {
    Active = 1,
    Inactive = 2,
    Pending = 17,
}

export enum PermissionType {
    EditPortal = 'Edit Portal Data',
}

@HasMany(PermissionModel, 'permissions')
@HasOne(UserRoleInfo, 'roleInfo')
export class User extends Model {
    public endpoint = 'users';
    public active_contracts: number;
    public active_from: string;
    public code?: string;
    public avatar: string;
    public company: Company;
    public email: string;
    public first_name: string;
    public id: number;
    public last_name: string;
    public status: any;
    public permissions: Array<PermissionModel>;
    public street: string;
    public postal_code: string;
    public phone: string;
    public employee_number: string;
    public salutation: string;
    public city_id: number;
    public roles: Array<string> = [];
    public individual_settings = false;
    public insurance_rate_subsidy: boolean;
    public insurance_rate_subsidy_type: string;
    public insurance_rate_subsidy_amount: number;
    public service_rate_subsidy: boolean;
    public service_rate_subsidy_type: string;
    public service_rate_subsidy_amount: number;
    public leasing_rate_subsidy: boolean;
    public leasing_rate_subsidy_type: string;
    public leasing_rate_subsidy_amount: number;
    public max_user_contracts: number;
    public max_user_amount: number;
    public min_user_amount: number;
    public current_contracts: number;
    public current_offers: number;
    public current_orders: number;
    public created_at;
    public is_accept_offer = false;
    private _fullName: string;
    public audits: Array<Audit>;
    public is_system: boolean;
    public roleInfo: UserRoleInfo;
    public is_child_admin: boolean;
    public supplier: Supplier;
    public supplier_id: number;
    public childs: User[];
    public hasChilds: boolean;
    public reverse_name: string;
    public apartment_workplace_distance: number;
    public portal: Portal;

    get fullName(): string {
        if (this._fullName) {
            return this._fullName;
        }
        return `${this.first_name} ${this.last_name}`;
    }

    set fullName(value) {
        this._fullName = value;
    }

    public isEmployee() {
        let findedEmployee = false;
        this.roles.forEach(function(value) {
            if (value === 'Employee') {
                findedEmployee = true;
            }
        });
        return findedEmployee;
    }

    public isCompanyAdmin() {
        let findedCa = false;
        this.roles.forEach(function(value) {
            if (value === 'Company Admin') {
                findedCa = true;
            }
        });
        return findedCa;
    }

    public isMixedAdmin() {
        if (this.roles.length < 2) {
            return false;
        }
        let findedCa = false;
        let findedEmployee = false;
        this.roles.forEach(function (value) {
            if (value === 'Company Admin') {
                findedCa = true;
            } else if (value === 'Employee') {
                findedEmployee = true;
            }
        });
        return findedCa && findedEmployee;
    }

    public isLeasingSubsidyApplied() {
        return !!this.leasing_rate_subsidy;
    }

    public isLeasingSubsidyType(coverageType: SubsidyTypes) {
        return this.leasing_rate_subsidy_type === coverageType;
    }

    public getLeasingSubsidyAmount() {
        return this.leasing_rate_subsidy_amount;
    }

    public isInsuranceSubsidyApplied() {
        return !!this.insurance_rate_subsidy;
    }

    public isInsuranceSubsidyType(coverageType: SubsidyTypes) {
        return this.insurance_rate_subsidy_type === coverageType;
    }

    public getInsuranceSubsidyAmount() {
        return this.insurance_rate_subsidy_amount;
    }

    public isServiceSubsidyApplied() {
        return !!this.service_rate_subsidy;
    }

    public isServiceSubsidyType(coverageType: SubsidyTypes) {
        return this.service_rate_subsidy_type === coverageType;
    }

    public getServiceSubsidyAmount() {
        return this.service_rate_subsidy_amount;
    }

    public getLeasingSubsidyOf(leasingRate: Dinero): Dinero {
        if (this.isLeasingSubsidyApplied()) {
            if (this.isLeasingSubsidyType(SubsidyTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getLeasingSubsidyAmount() * 100),
                    currency: 'EUR'
                });
            } else {
                const leasingRateSubsidyRatio =
                    this.getLeasingSubsidyAmount() / 100;
                return leasingRate.multiply(leasingRateSubsidyRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }

    public getInsuranceSubsidyOf(insuranceRate: Dinero): Dinero {
        if (this.isInsuranceSubsidyApplied()) {
            if (this.isInsuranceSubsidyType(SubsidyTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getInsuranceSubsidyAmount() * 100),
                    currency: 'EUR'
                });
            } else {
                const insuranceSubsidyRatio =
                    this.getInsuranceSubsidyAmount() / 100;
                return insuranceRate.multiply(insuranceSubsidyRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }

    public getServiceSubsidyOf(serviceRate: Dinero): Dinero {
        if (this.isServiceSubsidyApplied()) {
            if (this.isServiceSubsidyType(SubsidyTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getServiceSubsidyAmount() * 100),
                    currency: 'EUR'
                });
            } else {
                const serviceSubsidyRatio =
                    this.getServiceSubsidyAmount() / 100;
                return serviceRate.multiply(serviceSubsidyRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }
}
