export function Mixin<T>(...mixins): new () => T {
    class MixinAbstractType {
        constructor() {
            mixins.forEach((mixin) => Object.assign(this, new mixin()));
        }
    }

    mixins.forEach((mixin) => {
        Object.getOwnPropertyNames(mixin.prototype).forEach((name) => {
            MixinAbstractType.prototype[name] = mixin.prototype[name];
        });
    });
    return <any>MixinAbstractType;
}
