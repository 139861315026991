import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[password][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PasswordDirective),
            multi: true
        }
    ]
})
export class PasswordDirective implements Validator {

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const password = control.value;
        let email = control.root.get('email');
        let name = control.root.get('first_name');
        let surname = control.root.get('last_name');
        email = email ? email.value : null;
        name = name ? name.value : null;
        surname = surname ? surname.value : null;

        if (password && !password.match(/^(?=.*\d)(?=.*[^a-zA-Z\d\s:])(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,15}$/)) {
            return {
                password: `Ungültiges Passwort`
            };
        }
        if (password && (password.includes(email) || password.includes(name) || password.includes(surname))) {
            return {
                password: `Ungültiges Passwort`
            };
        }
        return null;
    }
}
