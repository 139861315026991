import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AuthSettingsService {
    public path: string;
    public pathSubject = new ReplaySubject<string>();

    public token: string;
    public tokenSubject = new ReplaySubject<string>();

    public currentModule: string;
    public currentModuleSubject = new ReplaySubject<string>();

    public roles: Array<string>;
    public rolesSubject = new ReplaySubject<Array<string>>();

    public permissions: Array<string>;
    public permissionsSubject = new ReplaySubject<Array<string>>();

    constructor() {
        this.pathSubject.subscribe((value) => {
            this.path = value;
        });

        this.tokenSubject.subscribe((value) => {
            this.token = value;
        });

        this.currentModuleSubject.subscribe((value) => {
            this.currentModule = value;
        });

        this.rolesSubject.subscribe((value) => {
            this.roles = value;
        });

        this.permissionsSubject.subscribe((value) => {
            this.permissions = value;
        });
    }

    updatePath(value) {
        this.pathSubject.next(value);
    }

    updateToken(value) {
        this.tokenSubject.next(value);
    }

    updateCurrentModule(value) {
        this.currentModuleSubject.next(value);
    }

    updateRoles(value) {
        this.rolesSubject.next(value);
    }

    updatePermissions(permissions: Array<{ name: string }>) {
        if (permissions) {
            this.permissionsSubject.next(permissions.map(p => p.name));
        }
    }

}
