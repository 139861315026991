import { NgModule } from '@angular/core';
import { AUTH_SERVICE, AuthModule } from 'ngx-auth';

import { TokenStorageService } from './token-storage.service';
import { AuthenticationService } from './auth.service';
import { ProfileService } from '../profile/profile.service';

export function factory(authenticationService: AuthenticationService) {
    return authenticationService;
}

@NgModule({
    imports: [ AuthModule ],
    providers: [
        TokenStorageService,
        AuthenticationService,
        {
            provide: AUTH_SERVICE,
            deps: [ AuthenticationService ],
            useFactory: factory
        },
        ProfileService
    ]
})
export class AuthenticationModule {}
