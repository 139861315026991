import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthSettingsService } from './auth-settings.service';

@Injectable()
export class TokenStorageService {

    constructor(private cookie: CookieService,
                private authSettings: AuthSettingsService) {
    }

    public getAccessToken(tokenKey): Observable<string> {
        return observableOf(this.cookie.get(`${tokenKey}`));
    }

    public getAccessTokenSync(tokenKey): string {
        return this.cookie.get(`${tokenKey}`);
    }

    public getAccessTokenExp(tokenKey): Observable<string> {
        return observableOf(this.cookie.get(`${tokenKey}_exp`));
    }


    public getAccessTokenExpSync(tokenKey): string {
        return this.cookie.get(`${tokenKey}_exp`);
    }

    public checkAccessTokenExpiration(tokenKey): boolean {
        return this.cookie.check(`${tokenKey}_exp`);
    }

    public checkAccessToken(tokenKey): boolean {
        return this.cookie.check(`${tokenKey}`);
    }

    public setAccessToken(token: string, tokenKey: string) {
        this.cookie.set(`${tokenKey}`, token, 0, '/', null, true, "Lax");
    }

    public setAccessTokenExpiration(tokenExpirationInSeconds: string, tokenKey: string) {
        const expirationMiliseconds = Date.now() + (parseInt(tokenExpirationInSeconds, 10) * 1000);
        this.cookie.set(`${tokenKey}_exp`, tokenExpirationInSeconds, new Date(expirationMiliseconds), '/', null, true, "Lax");
    }

    public clear(tokenKey) {
        this.cookie.delete(tokenKey, '/');
        this.cookie.delete(`${tokenKey}_exp`, '/');
    }

    public clearAll() {
        this.cookie.deleteAll('/');
    }
}
