import { Injectable } from '@angular/core';

@Injectable()
export class FaviconService {
    constructor() {
    }

    public setFavIcon(url: string): void {
        let favicon = document.querySelector('link[rel="shortcut icon"]');

        if (!favicon) {
            favicon = document.createElement('link');
            favicon.setAttribute('rel', 'shortcut icon');
            const head = document.querySelector('head');
            head.appendChild(favicon);
        }


        favicon.setAttribute('type', 'image/x-icon');
        favicon.setAttribute('href', url);
    }

}
