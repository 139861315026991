import { SupplierInfoInterface, DiscountMode} from './supplier.interface';
import { CityInterface } from './city.interface';
import { Model } from './model';

export enum SupplierStatus {
    Active = 7,
    Inactive = 8,
}

export class DeliveryOptions {
    type: string;
    subSupplierId: number | string;
    destination: string;
}

export class Supplier extends Model implements SupplierInfoInterface {
    public active_from: string;
    public address: string;
    public admin_email: string;
    public admin_first_name: string;
    public admin_last_name: string;
    public city: CityInterface;
    public code: string;
    public city_id: string | number;
    public companies: Array<object>;
    public employees_count: string | number;
    public id: number;
    public max_products_count: string | number;
    public name: string;
    public phone: string | number;
    public portal_id: string | number;
    public portals: Array<object>;
    public products_count: string | number;
    public status_id: SupplierStatus = SupplierStatus.Active;
    public pivot_status: number;
    public status: any;
    public statusBool: boolean;
    public updated_at: string;
    public vat: string;
    public zip: string;
    public logo: string;
    public option1: boolean;
    public option2: boolean;
    public option3 = true;
    public current_offers: number;
    public current_orders: number;
    public current_contracts: number;
    public option4: boolean;
    public option5: boolean;
    public option6: boolean;
    public credit_note_discount: number;
    public discount_mode : DiscountMode;
    public credit_note_discount_edited: boolean;
    public parent_supplier_id: number;
    public shipping: boolean;
    public offers_enabled: boolean;
    public parent_supplier: Supplier;
    public employee_insert_frame_number: boolean;
    public tag: string;

    constructor(supplier?: SupplierInfoInterface) {
        super();
        if (supplier) {
            this.active_from = supplier.active_from;
            this.address = supplier.address;
            this.admin_email = supplier.admin_email;
            this.admin_first_name = supplier.admin_first_name;
            this.admin_last_name = supplier.admin_last_name;
            this.city = supplier.city;
            this.city_id = supplier.city_id;
            this.logo = supplier.logo;
            this.code = supplier.code;
            this.companies = supplier.companies;
            this.employees_count = supplier.employees_count;
            this.id = supplier.id;
            this.max_products_count = supplier.max_products_count;
            this.name = supplier.name;
            this.phone = supplier.phone;
            this.portal_id = supplier.portal_id;
            this.portals = supplier.portals;
            this.products_count = supplier.products_count;
            this.status = supplier.status;
            if (supplier.status) {
                this.status_id = supplier.status.id;
            }
            this.updated_at = supplier.updated_at;
            this.vat = supplier.vat;
            this.zip = supplier.zip;
            this.current_offers = supplier.current_offers;
            this.current_orders = supplier.current_orders;
            this.current_contracts = supplier.current_contracts;
            this.credit_note_discount = supplier.credit_note_discount;
            this.credit_note_discount_edited = supplier.credit_note_discount_edited;
            this.discount_mode = supplier.discount_mode;
            this.employee_insert_frame_number = supplier.employee_insert_frame_number;
            this.parent_supplier_id = supplier.parent_supplier_id;
            this.parent_supplier = supplier.parent_supplier;
            this.shipping = supplier.shipping;
            this.tag = supplier.tag;
        }

        this.statusBool = this.statusCheck();
    }

    statusCheck() {
        return this.status_id === SupplierStatus.Active;
    }
}
