<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                        <img [src]="portalLogoUrl">
                </div>
                <div class="card">
                    <div class="card-body">
                        <h1 class="title">Login</h1>

                        <div class="row justify-content-center">
                            <div class="col-sm-8 text-danger" *ngIf="errorMessage" [innerHtml]="errorMessage"></div>
                            <form class="col-sm-8" #loginForm="ngForm" novalidate (ngSubmit)="login(loginForm)">
                                <div
                                    class="form-control-holder form-group">
                                    <input type="email"
                                           class="form-control"
                                           placeholder="E-Mail"
                                           name="email"
                                           [(ngModel)]="email"
                                           maxlength="180"
                                           required email>
                                    <mrc-validation-errors [invalid]="loginForm.controls.email?.invalid"
                                                           [dirty]="loginForm.controls.email?.dirty"
                                                           [errors]="loginForm.controls.email?.errors"
                                                           [direction]="['md-right']"></mrc-validation-errors>
                                </div>
                                <div class="form-control-holder form-group">
                                    <input
                                        type="password"
                                        class="form-control"
                                        placeholder="Passwort"
                                        [(ngModel)]="password"
                                        name="password"
                                        maxlength="60"
                                        required>
                                    <mrc-validation-errors [invalid]="loginForm.controls.password?.invalid"
                                                           [dirty]="loginForm.controls.password?.dirty"
                                                           [errors]="loginForm.controls.password?.errors"
                                                           [direction]="['md-right']"></mrc-validation-errors>
                                </div>
                                <div class="form-control-holder form-group" *ngIf="childCompanies?.length > 1">
                                    <ng-select
                                        [virtualScroll]="true"
                                        bindValue="id"
                                        bindLabel="name"
                                        [items]="childCompanies"
                                        [clearable]="false"
                                        name="company_id"
                                        class="form-control ng-select form-element"
                                        [(ngModel)]="companyId"
                                        [searchable]="true"
                                        placeholder="Bitte wählen Sie eine Option"
                                        required></ng-select>
                                </div>
                                <div class="form-group-btn">
                                    <button type="submit" class="btn btn-primary btn-block"
                                            [disabled]="submitProcess" >
                                        <span *ngIf="!submitProcess">Anmelden</span>
                                        <i class="fal fa-sync-alt" *ngIf="submitProcess">
                                            autorenew
                                        </i>
                                    </button>
                                </div>
                                <div class="links text-center">
                                    <a [routerLink]="'../password/forgot-password'" class="link-inherit">Passwort
                                        vergessen?</a>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #subcompanyModal>
    <div class="modal-body">
        <div class="close-wrap">
            <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="text-center">
            <div class="text-holder">
                <h2 class="title">
                    Firma wechseln
                </h2>
                <ng-select [virtualScroll]="true" bindValue="id" bindLabel="name" [items]="childCompanies" [clearable]="false"
                    name="company_id" class="form-control ng-select custom form-element mb-2" [(ngModel)]="companyId"
                    [searchable]="true" placeholder="Bitte wählen Sie eine Option"></ng-select>
            </div>

            <button class="btn btn-primary" (click)="loginToSubCompany()">Einloggen als</button>
        </div>
    </div>
</ng-template>
