import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

import { LoginComponent } from './login/login.component';
import { LoginDeutscheDienstradComponent } from './login-dd/login-dd.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceService } from './maintenance/maintenance.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RegistrationComponent } from './registration/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { ExternalLoginComponent } from './external/login/external-login.component';
import { LoginService } from './login.service';
import { ExternalLoginResolver } from './external/login/external-login.resolver';
import { ConsentComponent } from './external/consent/consent.component';
import { ConsentResolver } from './external/consent/consent.resolver';
import { CompaniesService } from '../companies/companies.service';
import { RedirectComponent } from './external/redirect/redirect.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Title } from '@angular/platform-browser';
import { UsersService } from '@app/users/users.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        TooltipModule.forRoot(),
        MatCheckboxModule,
        MatIconModule,
        NgSelectModule
    ],
    declarations: [
        LoginComponent,
        LoginDeutscheDienstradComponent,
        MaintenanceComponent,
        ExternalLoginComponent,
        ConsentComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegistrationComponent,
        ErrorPageComponent,
        RedirectComponent
    ],
    exports: [
        LoginComponent,
        LoginDeutscheDienstradComponent,
        MaintenanceComponent,
        ExternalLoginComponent,
        ConsentComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        RegistrationComponent,
        ErrorPageComponent
    ],
    providers: [
        LoginService,
        ExternalLoginResolver,
        ConsentResolver,
        CompaniesService,
        MaintenanceService,
        Title,
        UsersService
    ]
})
export class PublicModule {}
