import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[email][formControlName],[email][formControl],[email][ngModel]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: EmailDirective,
        multi: true
    }]
})
export class EmailDirective implements Validator {

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const fieldValue = control.value;

        if (fieldValue && !String(fieldValue).match(/^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]{1,}@[a-zA-Z0-9.-]{1,}[.][a-zA-Z0-9.]{2,14}$/i)) {
            return {
                email: 'Ungültige E-Mail'
            };
        }

        return null;
    }

}
