import { Component, OnInit } from '@angular/core';
import { LoginService } from '../core/services/login/login.service';
import { AuthenticationService } from '../core/services/auth/auth.service';
import { SettingsService } from '../core/services/settings/settings.service';
import { InterceptSettingsService } from '../core/services/http/intercept-settings.service';
import { AuthSettingsService } from '../core/services/auth/auth-settings.service';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent
} from '@angular/router';
import { FaviconService } from '../core/services/settings/favicon.service';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'mrc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [SettingsService],
})
export class AppComponent implements OnInit {
    public loginLogo = 'assets/images/logos/systemadministrator_logo.svg';
    public primaryColor = '#ec4640';
    public loading = true;

    public httpSettings = {
        url: `${environment.apiUrl}/system-api`,
        token: 'access_token',
        module: 'system-admin',
    };

    constructor(
        public settingsService: SettingsService,
        private loginService: LoginService,
        private authService: AuthenticationService,
        private interceptService: InterceptSettingsService,
        private authSettings: AuthSettingsService,
        private faviconService: FaviconService,
        private router: Router,
        private title: Title
    ) {}

    ngOnInit() {
        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
        this.title.setTitle('Benefit Portal Systemadministrator');
        this.interceptService.updateSettings(this.httpSettings);
        this.authSettings.updatePath('');
        this.authSettings.updateToken('access_token');
        this.authSettings.updateCurrentModule('admin');
        this.addSystemStyles();
        const settings = this.settingsService.settings;
        this.router.events.subscribe((res) => {
            if (res instanceof NavigationEnd) {
                if (res.url.indexOf('admin') === -1) {
                    this.settingsService.updateSettings({
                        ...settings,
                        color: this.primaryColor,
                    });
                }
            }
        });
        this.authService.loggedIn.subscribe(() =>
            this.router.navigateByUrl('/admin/dashboard')
        );
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true;
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            this.loading = false;
        }
    }

    addSystemStyles() {
        this.faviconService.setFavIcon('assets/favicons/favicon.so.ico');
        this.loginService.loginLogo = this.loginLogo;
        this.settingsService.updateSettings({
            ...this.settingsService.settings,
            color: this.primaryColor,
            logo: '',
        });
    }
}
