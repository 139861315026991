export class Status {
    id: number;
    label: string;
    icon?: string;
    type?: StatusTypes;
    table?: string;
    order?: number;
    hasTooltip?: boolean;
    tooltipContent?: string;
}

export enum StatusTypes{
    success = 'success',
    default = 'default',
    danger = 'danger',
    warning = 'warning',
    info = 'info'

}
