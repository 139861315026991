import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Profile } from '../../../shared/models/profile';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SentryScopeService } from '../auth/sentry-scope.service';

@Injectable()
export class ProfileService {

    private profileObservable: Observable<Profile>;



    constructor(
        public http: HttpClient,
        private sentryScope: SentryScopeService,
    ) {
    }

    getProfile(): Observable<Profile> {
        if (!this.profileObservable) {
            this.profileObservable = this.http.get<any>(`profile`).pipe(
                map(({ payload }) => new Profile(payload)),
                tap(profile => {
                    this.sentryScope.setUserByProfile(profile);
                }),
                catchError((error) => {
                    this.clearCache();
                    return throwError(error);
                }),
                shareReplay(1),
            );
        }

        return this.profileObservable;
    }

    getFreshProfile(): Observable<Promise<Profile>> {
        return this.http.get<any>(`profile`).pipe(
            map(async ({ payload }) => {
                const profile = new Profile(payload);
                const cachedProfile = await this.getProfile().toPromise();
                if (profile.apartment_workplace_distance !== cachedProfile.apartment_workplace_distance) {
                    this.clearCache();
                }
                return profile;
            }),
            catchError((error) => {
                return throwError(error);
            }));
    }

    updatePolicy(profile: Profile) {
        return this.http.put('profile', profile);
    }

    clearCache() {
        this.profileObservable = null;
    }
}
