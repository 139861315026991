import { Portal, PortalStatus } from '../shared/models/portal';
import { Factory } from '../shared/factory';
import { Injectable } from '@angular/core';
import { StatusFactory } from '../core/services/mercator/status.factory';

@Injectable({
    providedIn: 'root',
})
export class PortalFactory extends Factory<Portal> {
    constructor(public statusFactory: StatusFactory) {
        super(Portal);
    }

    create(): Portal {
        const portal = super.create();
        const status = this.statusFactory.create();
        status.id = PortalStatus.Active;
        portal.status = status;
        portal.allowEmployeeOfferCreation = true;
        return portal;
    }
}
